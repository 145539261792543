(function () {
  'use strict';

  angular
    .module('FeatureOuModule', ['alert-message'])
    .factory('FeatureOuService', ['Restangular', 'AlertMessage', FeatureOuService]);

  function FeatureOuService(Restangular, AlertMessage) {
    const service = {
      getFeatureOuConfig,
      getDecoratedVideoPlayerConfig,
      getDeviceScreenConfig,
      getEventsConfig,
      getVehicleRegistrationConfig,
      getVehicleTableConfig,
      getLastVisitedPageReload,
      getTrimbleLogo,
      getProductName,
      handleError
    };

    return service;

    function getFeatureOuConfig(ouId) {
      return Restangular.one(`configuracao/configuracoes/${ouId}/feature-ou`).get()
        .then((featureOu) => featureOu.plain())
        .catch(handleError);
    }

    function getDecoratedVideoPlayerConfig(ouId) {
      return getFeatureOuConfig(ouId).then((featureOu) => featureOu.decoratedVideoPlayer);
    }

    function getDeviceScreenConfig(ouId) {
      return getFeatureOuConfig(ouId).then((featureOu) => featureOu.deviceScreen);
    }

    function getEventsConfig(ouId) {
      return getFeatureOuConfig(ouId).then((featureOu) => featureOu.events);
    }

    function getVehicleRegistrationConfig(ouId) {
      return getFeatureOuConfig(ouId).then((featureOu) => featureOu.vehicleRegistration);
    }

    function getVehicleTableConfig(ouId) {
      return getFeatureOuConfig(ouId).then((featureOu) => featureOu.vehicleTable);
    }

    function getLastVisitedPageReload(ouId) {
      return getFeatureOuConfig(ouId).then((featureOu) => featureOu.lastVisitedPageReload);
    }

    function getTrimbleLogo(ouId) {
      return getFeatureOuConfig(ouId).then((featureOu) => featureOu.trimbleLogo);
    }

    function getProductName(ouId) {
      return getFeatureOuConfig(ouId).then((featureOu) => featureOu.vi20ProductName);
    }

    function handleError(error) {
      AlertMessage.create({
        type: 'error',
        message: 'An error occurred while loading the settings: ' + error.data.message,
        appendTo: '.alerta-aqui-geral'
      });
      return Promise.reject(error);
    }
  }
})();
