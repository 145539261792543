(function () {
  'use strict';

  /* global _, L */
  class BdvDetalhesCtrl {
    constructor($window, $stateParams, Bdv, MapasUtil, $filter, $scope, $compile, leafletData, Eventos, VFlash, $location,
      SpiderfierService, MarkerService, moment, Onboarding, MockBDVDetalhes, IDBDVDetalhes, ServiceVfiltro, FactoryVfiltro,
      $translate, Authenticator, MeasurementUnitConverterService, ConvertersService) {
      this.window = $window;
      this.stateParams = $stateParams;
      this.bdvService = Bdv;
      this.convertersService = ConvertersService;
      this.mapasUtilService = MapasUtil;
      this.spiderfierService = SpiderfierService;
      this.markerService = MarkerService;
      this.location = $location;
      this.onboarding = Onboarding;
      this.mockBDVDetalhes = MockBDVDetalhes;
      this.idConfluence = IDBDVDetalhes;
      this.serviceVfiltro = ServiceVfiltro;
      this.translate = $translate;
      this.filter = $filter;
      this.scope = $scope;
      this.compile = $compile;
      this.moment = moment;
      this.todasConducoes = false;
      this.veiculo = null;
      this.inicio = null;
      this.duracaoTotal = 0;
      this.duracao = null;
      this.consumoCombustivelTotal = 0;
      this.consumoCombustivel = 0;
      this.consumoEletricoTotal = 0;
      this.consumoEletrico = 0;
      this.distancia = 0;
      this.motoristas = [];
      this.detalhes = [];
      this.conducaoSelecionada = null;
      this.leafletData = leafletData;
      this.eventos = Eventos;
      this.flash = VFlash;
      this.tooltipMotoristas = false;
      this.logradouroInicio = null;
      this.logradouroFim = null;

      this.tiles = MapasUtil.tiles.veltec;
      this.defaults = MapasUtil.defaults;
      this.geoJson = {};
      this.mapCenter = MapasUtil.getCenter();
      this.markersGroup = new L.FeatureGroup();
      this.pathGroup = new L.FeatureGroup();
      this.eventoSelecionado = '';

      this.onClickTipoEvento = this.filtraEvento();
      this.onClickPosicao = this.destacarMarcador();
      this.markersPorPosicao = new Map();
      this.eventoDescida = this.translate.instant(this.eventos.DESCIDA.tipo);
      this.eventoDescidaFaixa1 = this.translate.instant(this.eventos.DESCIDA_FAIXA1.tipo);
      this.eventoDescidaFaixa2 = this.translate.instant(this.eventos.DESCIDA_FAIXA2.tipo);
      this.eventoDescidaFaixa3 = this.translate.instant(this.eventos.DESCIDA_FAIXA3.tipo);
      this.faixasDescida = {
        segundosFaixa1: 5,
        segundosFaixa2: 30
      };

      this.setMapEvents();
      ServiceVfiltro.init(FactoryVfiltro.get([]))
        .then(() => {
          this.roleMotoristaEditar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('MOTORISTA_EDITAR');
          this.roleDadosSensiveisVisualizar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('DADOS_SENSIVEIS_VISUALIZAR');
          this.updateFiltrosLocalStorage();
          this.loadServerData();
        })
        .catch(() => {
          this.leafletData.unresolveMap('bdv-map');
        });

      this.authenticator = Authenticator;
      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.authenticator.getUser().then(user => {
        this.measurementUnits = user.measurementUnits;
        this.fuelUnit = this.measurementUnitConverterService.getFuelUnit(this.measurementUnits.fuelMeasurement);
        this.distanceUnit = this.measurementUnitConverterService.getDistanceUnit(this.measurementUnits.distanceMeasurement);
        this.dateTimeFormat = this.measurementUnitConverterService.getDateFormat(this.measurementUnits.dateTimeFormat);
        this.fuelEfficiency = this.measurementUnitConverterService.getFuelEfficiencyUnit(this.measurementUnits.fuelMeasurement);
        this.energyEfficiency = this.measurementUnitConverterService.getEnergyEfficiencyUnit(this.measurementUnits.fuelMeasurement);
      });
    }

    updateFiltrosLocalStorage() {
      let filtroLocalstorage = angular.fromJson(this.window.localStorage.getItem(`vfiltro-${this.serviceVfiltro.factoryVfiltro.user.id}`)) || {};
      filtroLocalstorage.dateTime = [
        this.moment(this.stateParams.dia, 'YYYY-MM-DD').startOf('day').format('YYYY.MM.DD.HH.mm.ss.ZZ'),
        this.moment(this.stateParams.dia, 'YYYY-MM-DD').endOf('day').format('YYYY.MM.DD.HH.mm.ss.ZZ')
      ];
      filtroLocalstorage.veiculo = this.stateParams.veiculoId;
      this.window.localStorage.setItem(`vfiltro-${this.serviceVfiltro.factoryVfiltro.user.id}`, angular.toJson(filtroLocalstorage));
    }

    filtraEvento() {
      return (index) => {
        this.eventoSelecionado = index;
        if (this.detalhes.length === 0) {
          this.flash.filterWarn();
        } else if (this.todasConducoes) {
          this.selecionarTodasConducoes();
        } else {
          this.limparVariaveisLayers();
          this.popularDadosDaConducao(this.conducaoSelecionada);
          this.inserirConducaoNoMapa(this.conducaoSelecionada);
        }
      };
    }

    destacarMarcador() {
      return (posicao, centralizarFim) =>
        this.leafletData.getMap('bdv-map')
          .then(map => {
            if (posicao.miscInfo && posicao.miscInfo.diferencaAltitude !== -1) {
              this.diferencaAltitude = posicao.miscInfo.diferencaAltitude;
            }
            const marker = this.markersPorPosicao.get(posicao);
            if (centralizarFim && angular.isDefined(posicao.fim)) {
              const markerFim = this.markersPorPosicao.get(posicao.fim);
              map.fitBounds([marker.getLatLng(), markerFim.getLatLng()]);
            } else {
              map.panTo(marker.getLatLng());
            }
            marker.openPopup(marker.getLatLng());
          });
    }

    loadServerData() {
      this.isDemo = this.onboarding.startOnboarding(this.idConfluence);

      if (angular.isUndefined(this.stateParams.dia) ||
        angular.isUndefined(this.stateParams.veiculoId)) {
        return;
      }

      if (this.isDemo) {
        let retorno = angular.copy(this.mockBDVDetalhes);
        retorno.data = retorno.data.map(x => {
          if (this.stateParams.veiculoId === 1) {
            x.veiculo = 'VW81502 - AOI7238';
          } else if (this.stateParams.veiculoId === 2) {
            x.veiculo = 'FSADF45 - VXZ9045';
          } else if (this.stateParams.veiculoId === 3) {
            x.veiculo = 'VCBZ45 - UKT3556';
          } else {
            x.veiculo = 'ANGA01 - ANR1131';
          }

          x.motoristas = this.location.search().motorista;

          return x;
        });
        this.setInfo(retorno);
      } else {
        this.bdvService.getDetalhes(this.stateParams.veiculoId, this.stateParams.dia).then(data => this.setInfo(data));
      }
    }

    onMapControlInit() {
      this.leafletData.getMap('bdv-map').then(map => {
        this.scope.$broadcast('initPontoReferencia', map);
      });
    }

    setInfo(data) {
      this.detalhes = data.data.map(c => {
        // Icone parado 1 esta sobrecarregando. Removido até proximo sprint.
        c.posicoes.forEach(p => {
          if (p.evento === this.translate.instant(this.eventos.PARADO.tipo) && p.duracao <= 300) {
            p.evento = this.translate.instant('ce.enum.eventos.movimento');
          }
          if (p.evento === this.translate.instant('ce.enum.eventos.pontoDeReferencia')) {
            p.evento = this.translate.instant('ce.enum.eventos.movimento');
          }
        });
        this.faixasDescida = c.faixasDescida;
        c.posicoes = this.mapasUtilService.ajustarEventos(c.posicoes, c.faixasDescida);
        this.mapasUtilService.marcarPosicoesInvalidasNoInicio(c.posicoes);

        if (c.fim) {
          this.duracaoTotal += this.moment(c.fim, 'YYYY/MM/DD HH:mm:ssZ').diff(this.moment(c.inicio, 'YYYY/MM/DD HH:mm:ssZ'), 'seconds');
        }
        this.consumoCombustivelTotal += c.consumoCombustivelParado + c.consumoCombustivelMovimento + c.consumoCombustivelGpsInvalido;
        this.consumoEletricoTotal += c.consumoEletricoParado + c.consumoEletricoMovimento + c.consumoEletricoGpsInvalido;

        return c;
      });

      if (this.detalhes.length === 0) {
        this.flash.filterWarn();
      } else {
        this.inicio = this.detalhes[0].inicio;
        this.selecionarTodasConducoes();
        this.detalhes = this.convertMeasurements(this.detalhes);
      }
    }

    selecionarTodasConducoes() {
      this.limparVariaveisLayers();
      this.resumoPeriodo = this.detalhes;
      this.tempoPorFaixaRpm = {
        rpmMarchaLentaTempo: 0,
        rpmAbaixoVerdeTempo: 0,
        rpmVerdeEconomicaTempo: 0,
        rpmVerdePotenciaTempo: 0,
        rpmAmareloTempo: 0,
        rpmVermelhoTempo: 0
      };

      this.detalhes.forEach((element) => {
        this.veiculo = element.veiculo;
        this.distancia += element.distanciaPercorrida;

        const nomesMotoristas = this.getNomesMotoristas(element);
        this.motoristas.push(nomesMotoristas);
        element.nomesMotoristas = nomesMotoristas;
        element.vigenciaManual = element.vigencias && element.vigencias.some(v => v.origem === 'MANUAL');

        let posicoes = _.sortBy(this.mapasUtilService.flatPosicoes(element.posicoes), 'data'),
            posicoesValidas = posicoes.filter(p => p.valida);

        this.tempoPorFaixaRpm.rpmMarchaLentaTempo += element.rpmMarchaLentaTempo || 0;
        this.tempoPorFaixaRpm.rpmAbaixoVerdeTempo += element.rpmAbaixoVerdeTempo || 0;
        this.tempoPorFaixaRpm.rpmVerdeEconomicaTempo += element.rpmVerdeEconomicaTempo || 0;
        this.tempoPorFaixaRpm.rpmVerdePotenciaTempo += element.rpmVerdePotenciaTempo || 0;
        this.tempoPorFaixaRpm.rpmAmareloTempo += element.rpmAmareloTempo || 0;
        this.tempoPorFaixaRpm.rpmVermelhoTempo += element.rpmVermelhoTempo || 0;

        this.setPath(posicoesValidas);
        this.setPathEventos(posicoesValidas, this.eventoSelecionado);
      });

      this.todasConducoes = true;
      this.logradouroInicio = '';
      this.logradouroFim = '';
      this.duracao = this.duracaoTotal;
      this.consumoCombustivel = this.consumoCombustivelTotal;
      this.consumoEletrico = this.consumoEletricoTotal;
      if (this.consumoCombustivelTotal > 0) {
        this.mediaConsumo = this.distancia / this.consumoCombustivelTotal;
      } else {
        this.mediaConsumo = undefined;
      }
      if (this.consumoEletricoTotal > 0) {
        this.mediaConsumoEletrico = this.distancia / this.consumoEletricoTotal;
      } else {
        this.mediaConsumoEletrico = undefined;
      }
      this.insertMarkers(this.detalhes.map(d => this.mapasUtilService.posicoesFiltradas(d.posicoes)));
    }

    convertMeasurements(data) {
      if (this.measurementUnits.fuelMeasurement !== 'LITERS') {
        this.mediaConsumo = this.mediaConsumo * 2.352;
        this.consumoCombustivel = this.consumoCombustivel * 0.264172;
      }
      if (this.measurementUnits.distanceMeasurement !== 'KM') {
        this.convertersService.handleKmToMiles(data);
      }

      return data.map(record => {
        let recordCopy = Object.assign({}, record);

        const conversionFactors = {
          distanciaPercorrida: this.convertersService.handleKmToMiles(),
          consumoCombustivel: 0.264172,
          mediaConsumo: 2.352,
          distanciaPercorridaConvertida: this.convertersService.handleKmToMiles(),
          mediaConsumoEletrico: 0.621371
        };

        for (let field in conversionFactors) {
          if (field in recordCopy) {
            let isDistanceField = ['distanciaPercorrida', 'distanciaPercorridaConvertida', 'mediaConsumoEletrico'].includes(field),
                isFuelField = ['consumoCombustivel', 'mediaConsumo'].includes(field);
            if (isDistanceField && this.measurementUnits.distanceMeasurement !== 'KM' ||
                isFuelField && this.measurementUnits.fuelMeasurement !== 'LITERS') {
              recordCopy[field] *= conversionFactors[field];
            }
          }
        }
        return recordCopy;
      });
    }

    limparVariaveisLayers() {
      this.pathGroup.clearLayers();
      this.markersGroup.clearLayers();
      this.duracao = null;
      this.consumoCombustivel = 0;
      this.consumoEletrico = 0;
      this.motoristas = [];
      this.distancia = 0;
      this.diferencaAltitude = null;
      this.markersPorPosicao.clear();
    }

    selecionarConducao(conducao) {
      this.limparVariaveisLayers();
      this.resumoPeriodo = [conducao];
      this.popularDadosDaConducao(conducao);
      this.inserirConducaoNoMapa(conducao);
    }

    popularDadosDaConducao(conducao) {
      this.conducaoSelecionada = conducao;
      this.logradouroInicio = this.conducaoSelecionada.logradouroInicio;
      this.logradouroFim = this.conducaoSelecionada.logradouroFim;
      this.todasConducoes = false;
      this.motoristas.push(this.getNomesMotoristas(conducao));
      this.veiculo = conducao.veiculo;
      this.distancia += conducao.distanciaPercorrida;
      this.consumoCombustivel = conducao.consumoCombustivelParado + conducao.consumoCombustivelMovimento + conducao.consumoCombustivelGpsInvalido;
      this.mediaConsumo = conducao.mediaConsumo;
      this.consumoEletrico = conducao.consumoEletricoParado + conducao.consumoEletricoMovimento + conducao.consumoEletricoGpsInvalido;
      this.mediaConsumoEletrico = conducao.mediaConsumoEletrico;
      this.tempoPorFaixaRpm = {
        rpmMarchaLentaTempo: conducao.rpmMarchaLentaTempo || 0,
        rpmAbaixoVerdeTempo: conducao.rpmAbaixoVerdeTempo || 0,
        rpmVerdeEconomicaTempo: conducao.rpmVerdeEconomicaTempo || 0,
        rpmVerdePotenciaTempo: conducao.rpmVerdePotenciaTempo || 0,
        rpmAmareloTempo: conducao.rpmAmareloTempo || 0,
        rpmVermelhoTempo: conducao.rpmVermelhoTempo || 0
      };
      this.duracao = conducao.fim ?
        this.moment(conducao.fim, 'YYYY/MM/DD HH:mm:ssZ')
          .diff(this.moment(conducao.inicio, 'YYYY/MM/DD HH:mm:ssZ'), 'seconds') : null;
    }

    inserirConducaoNoMapa(conducao) {
      let posicoes = _.sortBy(this.mapasUtilService.flatPosicoes(conducao.posicoes), 'data');
      this.setPath(posicoes);
      this.setPathEventos(posicoes, this.eventoSelecionado);
      this.insertMarkers([this.mapasUtilService.posicoesFiltradas(conducao.posicoes)]);
    }

    setMapEvents() {
      angular.extend(this.scope, {
        events: {
          map: {
            enable: ['click'],
            logic: 'emit'
          },
          markers: {
            enable: ['click'],
            logic: 'emit'
          }
        }
      });
    }

    insertMarkers(trechos) {
      this.leafletData.getMap('bdv-map').then(
        map => {
          const oms = this.spiderfierService.createSpiderfier(map),
              posicoes = this.addPosicaoDescidaFimDescidas(trechos);
          this.markersGroup.clearLayers();

          this.markersPorPosicao = this.markerService
            .buildMarkersAssociadosAsPosicoes(posicoes, this.eventoSelecionado, 'bdv-');

          this.markersPorPosicao.forEach(marker => {
            marker.setPopupContent(this.compile('<div>' + marker.getPopup().getContent() + '</div>')(this.scope)[0]);
            this.markersGroup.addLayer(marker);
            if (!this.eventos.isLocalizacao(marker.options.evento)) {
              oms.addMarker(marker);
            }
          });

          if (!map.hasLayer(this.markersGroup)) {
            map.addLayer(this.markersGroup);
          }
          if (this.markersGroup.getLayers().length > 0) {
            map.fitBounds(this.markersGroup.getBounds(), {padding: [100, 100]});
          }
        }
      );
    }

    addPosicaoDescidaFimDescidas(trechos) {
      return _.flatten(trechos).reduce(
        (acc, posicao) => {
          acc.push(posicao);
          if (posicao.evento && posicao.evento.includes(this.eventoDescida) && angular.isDefined(posicao.fim)) {
            switch (posicao.evento) {
              case this.eventoDescidaFaixa1:
                posicao.fim.evento = this.translate.instant(this.eventos.DESCIDA_FAIXA1_FIM.tipo);
                break;
              case this.eventoDescidaFaixa2:
                posicao.fim.evento = this.translate.instant(this.eventos.DESCIDA_FAIXA2_FIM.tipo);
                break;
              default:
                posicao.fim.evento = this.translate.instant(this.eventos.DESCIDA_FAIXA3_FIM.tipo);
                break;
            }
            posicao.fim.temCoordenada = true;
            posicao.fim.descricao = this.translate.instant(this.eventos.DESCIDA_FAIXA1_FIM.descricao);
            acc.push(posicao.fim);
          }
          return acc;
        }, []);
    }

    setPath(posicoes) {
      this.leafletData.getMap('bdv-map').then(
        map => {
          let routeLines = L.polyline(posicoes.map(p => [p.latitude, p.longitude]), {
            color: '#3388FF',
            opacity: 0.75,
            weight: 3
          });
          L.polylineDecorator(routeLines, {
            patterns: [{
              offset: 0,
              repeat: 300,
              symbol: L.Symbol.arrowHead({
                pixelSize: 12,
                pathOptions: {fillOpacity: 0.75, color: '#0033FF', weight: 0}
              })
            }]
          }).addTo(this.pathGroup);
          this.pathGroup.addLayer(routeLines);

          if (!map.hasLayer(this.pathGroup)) {
            map.addLayer(this.pathGroup);
          }
        }
      );
    }

    setPathEventos(posicoes, e) {
      this.leafletData.getMap('bdv-map').then(
        map => {
          posicoes
            .filter(p => this.isEvento(p, e))
            .map(p => {
              let coord = [[p.latitude, p.longitude]],
                  routeEventsLines = null;
              if (angular.isDefined(p.fim)) {
                coord = coord.concat(this.getPosicoesDentroDoPeriodoDoEvento(p, posicoes));
                coord.push([p.fim.latitude, p.fim.longitude]);
              }
              routeEventsLines = L.polyline(coord, {
                color: 'red',
                weight: 3
              });
              this.pathGroup.addLayer(routeEventsLines);
            });

          if (!map.hasLayer(this.pathGroup)) {
            map.addLayer(this.pathGroup);
          }
        }
      );
    }

    getPosicoesDentroDoPeriodoDoEvento(posicao, posicoes) {
      return posicoes
        .filter(p => this.isDentroDoPeriodo(p, posicao))
        .map(p=> [p.latitude, p.longitude]);
    }

    isDentroDoPeriodo(posicao, posicaoPeriodo) {
      return new Date(posicaoPeriodo.data) < new Date(posicao.data) &&
        new Date(posicaoPeriodo.fim.data) > new Date(posicao.data);
    }

    isEvento(p, e) {
      if (e === this.translate.instant(this.eventos.EXCESSO_VELOCIDADE.tipo)) {
        return this.eventos.isExcessoVelocidade(p.evento);
      } else if (e === this.translate.instant(this.eventos.CURVA_BRUSCA.tipo)) {
        return this.eventos.isCurvaBrusca(p.evento);
      }
      return e === '' || p.evento === e;
    }

    onLayerSwitch(layerWrapper) {
      this.tiles = layerWrapper.getLayerConfig();
    }

    getNomesMotoristas(conducao) {
      if (conducao.vigencias && conducao.vigencias.length > 0) {
        const todasAsPosicoesTemVigencia = conducao.posicoes.every(posicao => {
          return conducao.vigencias.some(v => this.moment(posicao.data).isBetween(v.inicio, v.fim));
        });
        let nomesMotoristas = conducao.vigencias.map(vigencia => vigencia.motoristaNome);
        return todasAsPosicoesTemVigencia ?
          nomesMotoristas.join(', ') :
          [...nomesMotoristas, this.translate.instant('ce.common.motorista.semIdentificacao')].join(', ');
      }
      return this.translate.instant('ce.common.motorista.semIdentificacao');
    }

    configurarCadastroConducao() {
      const btnAdicionarConducao = angular.element('vfwc-btn-adicionar-conducao')[0];
      if (btnAdicionarConducao) {
        btnAdicionarConducao.veiculoId = Number(this.stateParams.veiculoId);
      }
    }
  }

  angular
    .module('relatorios.bdv')
    .controller('BdvDetalhesCtrl', BdvDetalhesCtrl);
}());
