(function () {
  'use strict';

  class ProntuarioLink {
    constructor(FeaturehubJsSdk, moment, FeatureHubConfigKeys, Authenticator) {
      this.featurehubJsSdk = FeaturehubJsSdk;
      this.moment = moment;
      this.authenticator = Authenticator;
      this.enableHiperlink = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.ENABLE_HIPERLINK_DRIVER_RECORD);
      this.vFiltroStartDateFormat = 'YYYY.MM.DD.00.00.00.ZZ';
      this.vFiltroEndDateFormat = 'YYYY.MM.DD.23.59.59.ZZ';
    }

    handleProntuarioLink(defaultValue) {
      if (this.featurehubJsSdk.isFeatureEnabled('TTLAINT1461_date_filter_of_driver_record_page')) {
        const date = new Date(),
            month = 'month';

        date.setDate(date.getDate() - 1);

        switch (defaultValue) {
          case 'ontem':
            return `${this.moment(date).format(this.vFiltroStartDateFormat)},${this.moment(date).format(this.vFiltroEndDateFormat)},personalizado`;

          case 'mesAtual':
            return `${this.moment().format(this.vFiltroStartDateFormat.replace('DD', '01'))},${this.moment(date).format(this.vFiltroEndDateFormat)},personalizado`;

          case 'ultimoMes':
            return `${this.moment()
              .subtract(1, month)
              .startOf(month)
              .format(this.vFiltroStartDateFormat)},${this.moment()
              .subtract(1, month)
              .endOf(month)
              .format(this.vFiltroEndDateFormat)},monthly`;

          case 'ultimos3Meses':
            return `${this.moment()
              .subtract(3, month)
              .startOf(month)
              .format(this.vFiltroStartDateFormat)},${this.moment()
              .subtract(1, month)
              .endOf(month)
              .format(this.vFiltroEndDateFormat)},monthly`;

          case 'ultimos6Meses':
            return `${this.moment()
              .subtract(6, month)
              .startOf(month)
              .format(this.vFiltroStartDateFormat)},${this.moment()
              .subtract(1, month)
              .endOf(month)
              .format(this.vFiltroEndDateFormat)},monthly`;

          default:
            return defaultValue;
        }
      }
      return defaultValue;
    }

    goToLinkProntuarioSinceFirstDayOfMonth(uoId, motoristaId) {
      if (motoristaId === null) {
        return '';
      }
      if (uoId === null) {
        return '';
      }

      const date = new Date();
      date.setDate(date.getDate() - 1);
      let dateStart = new Date(date.getFullYear(), date.getMonth(), 1);

      return `prontuario?vfiltro=uo:${uoId};dateTime:${this.moment(dateStart).format(this.vFiltroStartDateFormat)},${this.moment(date).format(this.vFiltroEndDateFormat)},personalizado;motorista:${motoristaId}`;
    }

    hasRoleToViewProntuario(user) {
      return this.authenticator.hasRole('PRONTUARIO_MOTORISTA_VISUALIZAR') &&
      user.permissoesSolucao.includes('DADOS_SENSIVEIS_VISUALIZAR') &&
      (user.permissoesSolucao.includes('PRONTUARIO_MOTORISTA_VISUALIZAR') ||
        user.permissoesSolucao.includes('RANKING_GERAL_VISUALIZAR')) &&
      this.enableHiperlink;
    }
  }

  angular
    .module('ProntuarioLinkModule', [
      'FeatureFlagModule'
    ])
    .service('ProntuarioLinkService', ProntuarioLink);
}());
