(function () {
  'use strict';

  class MeasurementUnitConverterService {

    constructor() { }

    getDateTimeFormat(dateTimeFormat) {
      return dateTimeFormat === 'BRITISH' ? 'dd/MM/yy HH:mm:ss' : 'MM/dd/yy HH:mm:ss';
    }

    getDateFormat(dateTimeFormat) {
      return dateTimeFormat === 'BRITISH' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    }

    getDateTimeFormatYearWithTwo(dateTimeFormat) {
      return dateTimeFormat === 'BRITISH' ? 'dd/MM/yy' : 'MM/dd/yy';
    }

    getDistanceUnit(distanceMeasurement) {
      return distanceMeasurement === 'KM' ? 'Km' : 'mi';
    }

    getSpeedUnit(speedMeasurement) {
      return speedMeasurement === 'KM' ? 'Km/h' : 'mph';
    }

    getFuelUnit(fuelMeasurement) {
      return fuelMeasurement === 'LITERS' ? 'L' : 'gal';
    }

    getFuelEfficiencyUnit(fuelMeasurement) {
      return fuelMeasurement === 'LITERS' ? 'Km/L' : 'mpg';
    }

    getEnergyEfficiencyUnit(fuelMeasurement) {
      return fuelMeasurement === 'LITERS' ? 'Km/kWh' : 'mi/kWh';
    }
}

  angular
    .module('ModuleMeasurementUnitConverter', [])
    .service('MeasurementUnitConverterService', MeasurementUnitConverterService);
}());
