(function () {
  'use strict';

  class ProntuarioV2Ctrl {
    constructor($uibModal, $location, Keys, Authenticator, ServiceVfiltro, FactoryVfiltro, safetyService, SolucoesEnum, FeatureFlag, $scope, $window, $document, $log, $translate, FeaturehubJsSdk) {
      this.keys = Keys;
      this.authenticator = Authenticator;
      this.serviceVfiltro = ServiceVfiltro;
      this.factoryVfiltro = FactoryVfiltro;
      this.safetyService = safetyService;
      this.featureFlag = FeatureFlag;
      this.solucoesEnum = SolucoesEnum;
      this.scope = $scope;
      this.window = $window;
      this.document = $document[0];
      this.log = $log;
      this.modalService = $uibModal;
      this.translate = $translate;
      this.featurehubJsSdk = FeaturehubJsSdk;

      this.safetyApp = 'safetyApp';
      this.pageComponent = './Prontuario';
      this.safetyElement = 'safety-driver-record';
      this.component = null;
      this.container = this.document.getElementById(this.safetyElement);
      this.solucaoId = this.featureFlag.SOLUCAO_REC_FACIAL ? this.solucoesEnum.RECONHECIMENTO_FACIAL : null;

      const search = $location.search();
      this.currentTab = search && search.tab ? search.tab : 'indicators';

      this.consultar();

      this.scope.$on('$destroy', () => {
        this.unmountReactComponent();
      });
    }

    consultar() {
      const isFeatureEnabled = this.featurehubJsSdk.isFeatureEnabled('TTLAINT1461_date_filter_of_driver_record_page');
      this.serviceVfiltro
        .init(
          this.factoryVfiltro.get([
            {
              name: 'uoId',
              key: this.keys.uo.name
            },
            {
              key: this.keys.dateTime.name,
              dateLimit: 30,
              format: 'YYYY-MM-DD',
              labelSegmentPersonalizado: isFeatureEnabled
                ? this.translate.instant('v3gwc.vfiltro.tempo.daily')
                : this.translate.instant('v3gwc.vfiltro.personalizado'),
              defaultValues: isFeatureEnabled
                ? {
                  monthly: {
                    active: true,
                    dateLimit: 365
                  }
                }
                : {
                  ontem: {
                    active: true
                  },
                  ultimos7dias: {
                    active: true
                  },
                  ultimos30dias: {
                    active: true
                  }
                }
            },
            {
              name: 'motorista',
              key: this.keys.motorista.name,
              required: true,
              onlyActive: false
            }
          ])
        )
        .then((filtro) => {
          this.isMonthly = filtro.find((f) => f.key === this.keys.dateTime.name).value.tipo === 'monthly';
          this.vfiltroBackend = this.serviceVfiltro.getFiltroBackendNovo(filtro);
          this.authenticator.getUser().then(user => {
            this.loadReact(user);
          });
        });
    }

    loadReact(user) {
      this.safetyService
        .initialize()
        .then(() =>
          this.safetyService.getComponent(this.safetyApp, this.pageComponent)
        )
        .then((page) => {
          this.component = page().default;
          this.loadComponent(user);
        })
        .catch((error) => {
          this.log.error('Error occurred: ', error);
        });
    }

    loadComponent(user) {
      this.window.ReactDOM.render(
        this.window.React.createElement(this.component, {
          currentTab: this.currentTab,
          driverId: this.vfiltroBackend.motorista,
          endDate: this.vfiltroBackend.fim,
          handleOpenModalUpload: this.handleOpenModalUpload.bind(this),
          isMonthly: this.isMonthly,
          solucaoRecFacialId: this.solucaoId,
          startDate: this.vfiltroBackend.inicio,
          uoId: this.vfiltroBackend.uoId,
          user: user
        }),
        this.container
      );
    }

    handleOpenModalUpload(event) {
      this.modalService
        .open({
          animation: true,
          templateUrl: 'prontuario/modal/upload/upload-prontuario.tpl.html',
          controller: 'UploadProntuarioCtrl',
          controllerAs: '$ctrl',
          resolve: {
            motoristaId: () => this.vfiltroBackend.motorista,
            arquivo: () => event.detail,
            user: () => this.serviceVfiltro.factoryVfiltro.user
          }
        })
        .result
        .then(() => {
          /*eslint-disable */
          const ev = new Event('reload-infracoes-prontuario');
          document.dispatchEvent(ev);
          /*eslint-enable */
        });
    }

    handleGoToOldProntuario() {
      return '/prontuario-antigo';
    }

    unmountReactComponent() {
      if (this.window.ReactDOM && this.container) {
        if (this.window.ReactDOM.unmountComponentAtNode(this.container)) {
          this.log.log('React component unmounted');
        } else {
          this.log.log('React component was not mounted.');
        }
      } else {
        this.log.log('Container not found or React component was not mounted.');
      }
    }
  }

  angular
    .module('ProntuarioV2Module')
    .controller('ProntuarioV2Ctrl', ProntuarioV2Ctrl);
}());
