(function () {
  'use strict';

  class TiposEventosCtrl {
    constructor($scope, $uibModal, Restangular, AlertMessage, AlertasEnum, FeatureFlag,
      Keys, ServiceVfiltro, FactoryVfiltro, $translate, ConfigEventosService, FeaturehubJsSdk, FeatureHubConfigKeys) {
      this.scope = $scope;
      this.modalService = $uibModal;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.alertasEnum = AlertasEnum;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.translate = $translate;
      this.featureFlag = FeatureFlag;
      this.configEventosService = ConfigEventosService;

      this.uoId = 0;
      this.roleCadastrar = true;
      this.model = {configuracoes: [], checkAllAlertas: false, checkAllVideos: false};
      this.mapLabel = [
        {tipo: 'possivelColisao', enum: this.alertasEnum.POSSIVEL_COLISAO, titulo: this.translate.instant(this.alertasEnum.POSSIVEL_COLISAO.descricao)},
        {tipo: 'remocaoEquipamento', enum: this.alertasEnum.REMOCAO_EQUIPAMENTO, titulo: this.translate.instant(this.alertasEnum.REMOCAO_EQUIPAMENTO.descricao)},
        {tipo: 'speedlimit', enum: this.alertasEnum.EXCESSO_VELOCIDADE, titulo: this.translate.instant(this.alertasEnum.EXCESSO_VELOCIDADE.descricao)},
        {tipo: 'speedlimitVia', enum: this.alertasEnum.EXCESSO_VELOCIDADE_POR_VIA, titulo: this.translate.instant(this.alertasEnum.EXCESSO_VELOCIDADE_POR_VIA.descricao)},
        {tipo: 'curva', enum: this.alertasEnum.CURVA_BRUSCA, titulo: this.translate.instant(this.alertasEnum.CURVA_BRUSCA.descricao)},
        {tipo: 'freada', enum: this.alertasEnum.FREADA_BRUSCA, titulo: this.translate.instant(this.alertasEnum.FREADA_BRUSCA.descricao)},
        {tipo: 'freadaComAcionamentoPedal', enum: this.alertasEnum.FREADA_BRUSCA_COM_ACIONAMENTO_PEDAL, titulo: this.translate.instant(this.alertasEnum.FREADA_BRUSCA_COM_ACIONAMENTO_PEDAL.descricao)},
        {tipo: 'freadaSemAcionamentoPedal', enum: this.alertasEnum.FREADA_BRUSCA_SEM_ACIONAMENTO_PEDAL, titulo: this.translate.instant(this.alertasEnum.FREADA_BRUSCA_SEM_ACIONAMENTO_PEDAL.descricao)},
        {tipo: 'aceleracao', enum: this.alertasEnum.ACELERACAO_BRUSCA, titulo: this.translate.instant(this.alertasEnum.ACELERACAO_BRUSCA.descricao)},
        {tipo: 'banguela', enum: this.alertasEnum.BANGUELA, titulo: this.translate.instant(this.alertasEnum.BANGUELA.descricao)},
        {tipo: 'diagnosticosObd', enum: this.alertasEnum.DIAGNOSTICO_OBD, titulo: this.translate.instant(this.alertasEnum.DIAGNOSTICO_OBD.descricao)},
        {tipo: 'rpmExcessivo', enum: this.alertasEnum.EXCESSO_RPM, titulo: this.translate.instant(this.alertasEnum.EXCESSO_RPM.descricao)},
        {tipo: 'paradoLigado', enum: this.alertasEnum.PARADO_LIGADO, titulo: this.translate.instant(this.alertasEnum.PARADO_LIGADO.descricao)},
        {tipo: 'veiculoSemVigencia', enum: this.alertasEnum.VEICULO_SEM_VIGENCIA, titulo: this.translate.instant(this.alertasEnum.VEICULO_SEM_VIGENCIA.descricao)},
        {tipo: 'veiculoSemSinal', enum: this.alertasEnum.VEICULO_SEM_SINAL, titulo: this.translate.instant(this.alertasEnum.VEICULO_SEM_SINAL.descricao)},
        {tipo: 'manutencaoProgramada', enum: this.alertasEnum.MANUTENCAO_PROGRAMADA, titulo: this.translate.instant(this.alertasEnum.MANUTENCAO_PROGRAMADA.descricao)},
        {tipo: 'manutencaoProgramadaHorimetro', enum: this.alertasEnum.MANUTENCAO_PROGRAMADA_HORIMETRO, titulo: this.translate.instant(this.alertasEnum.MANUTENCAO_PROGRAMADA_HORIMETRO.descricao)},
        {tipo: 'distanciaPerigosa', enum: this.alertasEnum.DISTANCIA_PERIGOSA, titulo: this.translate.instant(this.alertasEnum.DISTANCIA_PERIGOSA.descricao)},
        {tipo: 'distracaoMotorista', enum: this.alertasEnum.DISTRACAO_MOTORISTA, titulo: this.translate.instant(this.alertasEnum.DISTRACAO_MOTORISTA.descricao)},
        {tipo: 'fadigaMotorista', enum: this.alertasEnum.FADIGA_MOTORISTA, titulo: this.translate.instant(this.alertasEnum.FADIGA_MOTORISTA.descricao)},
        {tipo: 'manuseioCelular', enum: this.alertasEnum.MANUSEIO_CELULAR, titulo: this.translate.instant(this.alertasEnum.MANUSEIO_CELULAR.descricao)},
        {tipo: 'quaseColisao', enum: this.alertasEnum.PREVISAO_COLISAO, titulo: this.translate.instant(this.alertasEnum.PREVISAO_COLISAO.descricao)},
        {tipo: 'usoCigarro', enum: this.alertasEnum.USO_CIGARRO, titulo: this.translate.instant(this.alertasEnum.USO_CIGARRO.descricao)},
        {tipo: 'motoristaAusente', enum: this.alertasEnum.MOTORISTA_AUSENTE, titulo: this.translate.instant(this.alertasEnum.MOTORISTA_AUSENTE.descricao)},
        {tipo: 'cameraObstruida', enum: this.alertasEnum.CAMERA_OBSTRUIDA, titulo: this.translate.instant(this.alertasEnum.CAMERA_OBSTRUIDA.descricao)},
        {tipo: 'permanenciaPonto', enum: this.alertasEnum.PERMANENCIA_PONTO, titulo: this.translate.instant(this.alertasEnum.PERMANENCIA_PONTO.descricao)},
        {tipo: 'semCinto', enum: this.alertasEnum.SEM_CINTO, titulo: this.translate.instant(this.alertasEnum.SEM_CINTO.descricao)},
        {tipo: 'rfidNaoCadastrado', enum: this.alertasEnum.RFID_NAO_CADASTRADO, titulo: this.translate.instant(this.alertasEnum.RFID_NAO_CADASTRADO.descricao)},
        {tipo: 'panico', enum: this.alertasEnum.PANICO, titulo: this.translate.instant(this.alertasEnum.PANICO.descricao)},
        {tipo: 'permanenciaCerca', enum: this.alertasEnum.PERMANENCIA_CERCA, titulo: this.translate.instant(this.alertasEnum.PERMANENCIA_CERCA.descricao)},
        {tipo: 'semCone', enum: this.alertasEnum.SEM_CONE, titulo: this.translate.instant(this.alertasEnum.SEM_CONE.descricao)},
        {tipo: 'semOculos', enum: this.alertasEnum.SEM_OCULOS, titulo: this.translate.instant(this.alertasEnum.SEM_OCULOS.descricao)},
        {tipo: 'semLuvas', enum: this.alertasEnum.SEM_LUVAS, titulo: this.translate.instant(this.alertasEnum.SEM_LUVAS.descricao)},
        {tipo: 'bocejo', enum: this.alertasEnum.BOCEJO, titulo: this.translate.instant(this.alertasEnum.BOCEJO.descricao)},
        {tipo: 'carona', enum: this.alertasEnum.CARONA, titulo: this.translate.instant(this.alertasEnum.CARONA.descricao)},
        {tipo: 'followingDistance', enum: this.alertasEnum.FOLLOWING_DISTANCE, titulo: this.translate.instant(this.alertasEnum.FOLLOWING_DISTANCE.descricao)},
        {tipo: 'stabilityControl', enum: this.alertasEnum.STABILITY_CONTROL, titulo: this.translate.instant(this.alertasEnum.STABILITY_CONTROL.descricao)},
        {tipo: 'collisionMitigation', enum: this.alertasEnum.COLLISION_MITIGATION, titulo: this.translate.instant(this.alertasEnum.COLLISION_MITIGATION.descricao)},
        {tipo: 'fcwBrakeExtAccDem', enum: this.alertasEnum.FCW_BRAKE_EXT_ACC_DEM, titulo: this.translate.instant(this.alertasEnum.FCW_BRAKE_EXT_ACC_DEM.descricao)},
        {tipo: 'hapticWarning', enum: this.alertasEnum.HAPTIC_WARNING, titulo: this.translate.instant(this.alertasEnum.HAPTIC_WARNING.descricao)}
      ];
      this.subItems = ['ultrapassagemIlegal'];
      this.alertsWithSubItem = [
        {name: 'distanciaPerigosa', subItem: 'ultrapassagemIlegal'}
      ];
      this.alertsDisableVideo = ['carona'];
      this.rideSpecificData = [
        {
          label: this.translate.instant(
            'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.ride.xOrMore', {x: 2}
          ),
          value: 2
        },
        {
          label: this.translate.instant(
            'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.ride.xOrMore', {x: 3}
          ),
          value: 3
        }
      ];
      this.alertsWithSpecificData = ['carona'];
      this.flagPremiumTelemetry = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.FEATURE_PREMIUM_TELEMETRY);

      this.serviceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name
        }
      ]))
      .then((filtro) => {
        this.roleCadastrar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('CONFIGURACAO_ALERTA_EDITAR');
        this.init(filtro.find(x => x.key === this.keys.uo.name).value.id);
      });
    }

    init(uoId) {
      this.uoId = uoId;

      if (this.featureFlag.SHOW_ULTRAPASSAGEM_ILEGAL) {
        this.handleFeatureFlag();
      }

      Promise.all([
        this.restangular.one(`configuracao/configuracoes/${this.uoId}/alerta`).get(),
        this.restangular.one(`sensor/uos/${this.uoId}/configuracoes/eventos`).get(),
        this.configEventosService.getConfigEventos()
      ])
      .then(([alertas, eventos, configEventos]) => {
        this.setConfiguracoes(alertas, eventos, configEventos);
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: 'Ocorreu um erro ao carregar as configurações',
          appendTo: '.alerta-aqui-geral'
        });
      });
    }

    setConfiguracoes(alertas, eventos, configEventos) {
      this.scope.$apply(() => {
        this.model.configuracoes = this.mapLabel
          .filter(alertaMapeado => !configEventos[alertaMapeado.tipo] || configEventos[alertaMapeado.tipo].enabled !== false)
          .filter(alertaMapeado => alertaMapeado.enum.isEnabled(configEventos))
          .filter(alertaMapeado => alertas.configuracoes[alertaMapeado.tipo]
            ? alertas.configuracoes[alertaMapeado.tipo].configuracaoVisivel
            : null
          )
          .map((item) => {
            if (item) {
              const [alerta, evento] = [alertas.configuracoes[item.tipo] || {}, eventos.configuracoes[item.tipo] || {}];
              return {
                item,
                alertaAtivo: !!alerta.ativo,
                eventoAtivo: evento.ativo,
                videoAtivo: !!alerta.videoAtivo,
                specificData: this.getSpecificData(alerta.specificDataHashMap, item.tipo, !!alerta.ativo)
              };
            }
          });
      });
    }

    getSpecificData(specificDataHashMap, eventType, active) {
      switch (eventType) {
        case 'carona':
          const value = specificDataHashMap && specificDataHashMap.peopleCount;
          return angular.isDefined(value) && active ? value : null;
        default:
          return null;
      }
    }

    handleFeatureFlag() {
      const indexDistanciaPerigosa = this.mapLabel.findIndex(config => config.tipo === 'distanciaPerigosa');

      if (indexDistanciaPerigosa !== -1) {
        this.mapLabel.splice(indexDistanciaPerigosa + 1, 0, {
          tipo: 'ultrapassagemIlegal',
          enum: this.alertasEnum.ULTRAPASSAGEM_ILEGAL,
          titulo: this.translate.instant(this.alertasEnum.ULTRAPASSAGEM_ILEGAL.descricao)
        });
      }
    }

    configuracoesToObject() {
      return this.model.configuracoes.reduce(({accAlertas, accEventos}, config) => {
        let specificData = null;
        if (this.alertsWithSpecificData.includes(config.item.tipo)) {
          specificData = this.handleSpecificData(config.item.tipo, config.specificData);
        }
        accAlertas[config.item.tipo] = {
          ativo: !!config.alertaAtivo,
          videoAtivo: !!config.videoAtivo,
          specificDataHashMap: config.alertaAtivo ? specificData : null
        };
        if (angular.isDefined(config.eventoAtivo)) {
          accEventos[config.item.tipo] = {ativo: !!config.eventoAtivo};
        }
        return {accAlertas, accEventos};
      }, {accAlertas: {}, accEventos: {}});
    }

    handleSpecificData(eventType, value) {
      switch (eventType) {
        case 'carona':
          return {peopleCount: value};
        default:
          return null;
      }
    }

    getEventTooltip(item) {
      return item.enum && item.enum.tooltip ? this.translate.instant(item.enum.tooltip) : null;
    }

    isEventTooltip(item) {
      return !!item.enum.tooltip;
    }

    isSpeedLimitVia(item) {
      return item.tipo === 'speedlimitVia';
    }

    isPossivelColisao(item) {
      return item.tipo === 'possivelColisao';
    }

    isEPIEvent(item) {
      return item.tipo === 'semCone' || item.tipo === 'semOculos' || item.tipo === 'semLuvas';
    }

    isIlegalOvertaking(item) {
      return item.tipo === 'ultrapassagemIlegal';
    }

    isRideEvent(item) {
      return item.tipo === 'carona';
    }

    isFuncionalidadeExperimental(item) {
      return this.isEPIEvent(item) ||
        this.isPossivelColisao(item) ||
        this.isIlegalOvertaking(item) ||
        this.isRideEvent(item)
      ;
    }

    isSubItem(item) {
      return this.subItems.indexOf(item.tipo) > -1;
    }

    checkAlerta(alertConfig) {
      const matchedItem = this.alertsWithSubItem.find((alert) => alert.name === alertConfig.item.tipo);

      if (angular.isDefined(alertConfig.eventoAtivo) && alertConfig.alertaAtivo) {
        alertConfig.eventoAtivo = true;
      }

      if (!alertConfig.alertaAtivo) {
        alertConfig.videoAtivo = false;
      }

      if (matchedItem) {
        const subItem = this.model.configuracoes.find((config) => config.item.tipo === matchedItem.subItem);
        this.handleSubItemConfig(subItem, alertConfig.alertaAtivo);
      }
    }

    handleSubItemConfig(subItem, isAlertaAtivo) {
      if (!subItem) {
        return;
      }

      if (!isAlertaAtivo) {
        Object.assign(subItem, {alertaAtivo: false, isDisabled: true});
      } else {
        subItem.isDisabled = false;
      }
    }

    checkSubItemConfig(configSubItem) {
      if (!configSubItem.alertaAtivo) {
        configSubItem.videoAtivo = false;
      }
    }

    checkFirst(isFirst, config, value) {
      if (isFirst && config.specificData === null) {
        config.specificData = value;
      }
      return angular.isDefined(config.specificData) && config.specificData === value;
    }

    checkAllAlertas() {
      this.model.configuracoes.forEach((item) => item.alertaAtivo = this.model.checkAllAlertas);
    }

    checkAllVideos() {
      this.model.configuracoes.forEach((item) => {
        if (item.alertaAtivo && !this.disableVideo(item.item.tipo)) {
          item.videoAtivo = this.model.checkAllVideos;
        }
      });
    }

    disableVideo(type) {
      return this.alertsDisableVideo.includes(type);
    }

    abrirModal() {
      this.modalService.open({
        animation: true,
        templateUrl: 'configuracoes/eventos/modal/info/info-eventos.tpl.html',
        controller: 'ModalInfoEventosCtrl',
        controllerAs: '$ctrl',
        size: 'lg'
      });
    }

    salvar() {
      const configs = this.configuracoesToObject(),
          configAlertas = {
            uoId: this.uoId,
            configuracoes: configs.accAlertas
          },
          configEventos = {
            uo: {id: this.uoId},
            configuracoes: configs.accEventos
          };

      // ensure all alerts will be saved and which are not being shown on the screen will be saved as false - STFM-1207
      this.mapLabel.forEach(label => {
        if (!configAlertas.configuracoes[label.tipo]) {
          configAlertas.configuracoes[label.tipo] = {
            ativo: false,
            videoAtivo: false
          };
        }
      });

      Promise.all([
        this.restangular.all(`configuracao/configuracoes/${this.uoId}/alerta`).customPUT(configAlertas),
        this.restangular.all(`sensor/uos/${this.uoId}/configuracoes/eventos`).post(configEventos)
      ]).then(() => {
        this.scope.formAlertas.$setPristine();

        this.scope.$apply(() => {
          this.alertMessage.create({
            type: 'success',
            message: 'Salvo com sucesso',
            appendTo: '.alerta-aqui'
          });
        });
      })
      .catch(() => {
        this.scope.$apply(() => {
          this.alertMessage.create({
            type: 'error',
            message: 'Ocorreu um erro ao salvar as configurações',
            appendTo: '.alerta-aqui'
          });
        });
      });
    }

    getGerarVideoText() {
      return this.flagPremiumTelemetry 
        ? 'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.gerarVideo.tituloV2'
        : 'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.gerarVideo.titulo'
    }

    getTooltipText() {
      return this.flagPremiumTelemetry
        ? 'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.gerarVideo.infoExtraV2'
        : 'ce.configuracao.eventos.itensConfiguraveis.visualizarAlertas.colunas.gerarVideo.infoExtra'
    }
  }

  angular
    .module('SubpaginasEventos')
    .controller('TiposEventosCtrl', TiposEventosCtrl);
}());
