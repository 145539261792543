/* global document, Intl */
(function () {
  'use strict';

  class MotoristaCtrl {
    constructor(HttpServiceV3g, $uibModal, $filter, AlertMessage, Motoristas, MiddlewareService, moment,
                Keys, ServiceVfiltro, FactoryVfiltro, ObjetosVfiltroService, GoogleTagManagerUtils, $state,
                VtabelaFactory, uiGridConstants, TipoPermissaoEnum, Authenticator, $translate, FeatureFlag, UrlConfigService,
                CadastroVeiculoService, SolucoesEnum, MeasurementUnitConverterService, FeatureOuService) {
      this.httpService = HttpServiceV3g;
      this.modalService = $uibModal;
      this.filter = $filter;
      this.alertMessage = AlertMessage;
      this.motoristaService = Motoristas;
      this.middlewareService = MiddlewareService;
      this.moment = moment;
      this.keys = Keys;
      this.tipoPermissaoEnum = TipoPermissaoEnum;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.authenticator = Authenticator;
      this.translate = $translate;
      this.featureFlag = FeatureFlag;
      this.state = $state;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.urlConfigService = UrlConfigService;
      this.cadastroVeiculoService = CadastroVeiculoService;
      this.solucoesEnum = SolucoesEnum;
      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.currentTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.uiGridConstants = uiGridConstants;
      this.vtabelaFactory = VtabelaFactory;
      this.vtabela = null;
      this.files = [];
      this.relatorio = null;
      this.currentPage = 0;
      this.tableState = this.objetosVfiltroService.getTableStateUrl();
      this.relatorioSafe = null;
      this.ativos = this.translate.instant('ce.cadastro.motoristas.tabela.coluna.status.ativos');
      this.ativoUpperCase = this.translate.instant('ce.cadastro.motoristas.tabela.coluna.status.ativoUpperCase');
      this.inativos = this.translate.instant('ce.cadastro.motoristas.tabela.coluna.status.inativos');
      this.inativoUpperCase = this.translate.instant('ce.cadastro.motoristas.tabela.coluna.status.inativoUpperCase');
      this.documentoStatus = {
        VENCIDO: {label: this.translate.instant('ce.cadastro.motoristas.tabela.coluna.documentoStatus.vencido'), class: 'text-red'},
        PROXIMO_DO_VENCIMENTO: {
          label: this.translate.instant('ce.cadastro.motoristas.tabela.coluna.documentoStatus.proximoDoVencimento'),
          class: 'text-yellow'},
        EM_DIA: {label: this.translate.instant('ce.cadastro.motoristas.tabela.coluna.documentoStatus.emDia'), class: 'text-black'}
      };
      this.possuiFoto = {
        LABEL: this.translate.instant('ce.cadastro.motoristas.tabela.coluna.possuiFoto.label'),
        SIM: this.translate.instant('ce.cadastro.motoristas.tabela.coluna.possuiFoto.sim'),
        NAO: this.translate.instant('ce.cadastro.motoristas.tabela.coluna.possuiFoto.nao')
      };
      this.user = null;
      this.uoWithRecFacial = false;
      this.tooltipApenas1Motorista = this.translate.instant('ce.cadastro.motoristas.botao.maisOpcoes.selecioneApenas1');
      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name
        },
        {
          key: this.keys.uoParceira.name
        }
      ]))
      .then(() => {
        this.roleCadastrar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('MOTORISTA_EDITAR');
        this.consultar();
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'cadastroMotorista', 'v1');
      });
      this.featureOuService = FeatureOuService;
      this.initUserConfig();
    }

    initUserConfig() {
      this.authenticator.getUser().then(user => {
        this.uoId = user ? user.uo.id : null;
        this.userPerfilId = user ? user.perfil.id : null;
        this.user = user;
        this.isAdminVeltec = user.perfil.id === 1;
        this.isTTC = user.dataOrigin === 'TTC';
        this.dateFormat = user.measurementUnits.dateTimeFormat;
        this.dateTimeFormat = this.measurementUnitConverterService.getDateTimeFormat(this.dateFormat);
        this.getVehicleRegistrationConfig(user.uo.id);
      });
    }

    getVehicleRegistrationConfig(userUoId) {
      this.featureOuService.getVehicleRegistrationConfig(userUoId).then((vehicleRegistration) => {
        this.featureNa = vehicleRegistration;
      }).catch((error) => {
        this.log.error('Error loading featureOu config: ', error);
      });
    }

    initVtabela() {
      return {
        appScopeProvider: this,
        exporterFieldCallback: (grid, row, col, input) => {
          if (col.name === 'ativo') {
            if (input) {
              return 'Ativo';
            }
            return 'Inativo';
          }
          if (col.name === 'vencimentoCnh') {
            return this.moment(input).format('DD/MM/YYYY');
          }
          return input;
        },
        columnDefs: [
          {
            name: 'nome',
            displayName: this.featureNa ? 'ce.cadastro.motoristas.tabela.coluna.driverId' : 'ce.cadastro.motoristas.tabela.coluna.nome',
            pinnedLeft: true,
            enableHiding: false,
            filterCellFiltered: true,
            headerCellFilter: 'translate',
            type: 'string',
            cellTemplate: `
            <div class="ui-grid-cell-contents">
              <i ng-if="row.entity.permissao.tipo === 'V'"
                class="fa fa-minus-circle permissao-icon"
                uib-tooltip="{{ 'ce.cadastro.motoristas.tabela.coluna.tooltipColunaNome' | translate }}">
              </i>
              <motorista-link
                motorista="row.entity.nome"
                motorista-id="row.entity.id"
                index="$index">
              </motorista-link>
            </div>`
          },
          {
            name: 'apelido',
            displayName: 'ce.cadastro.motoristas.tabela.coluna.apelido',
            type: 'string'
          },
          {name: 'cpf', displayName: 'ce.cadastro.motoristas.tabela.coluna.cpf', type: 'number', cellClass: 'text-center', headerCellClass: 'text-center'},
          {name: 'documentoIdentificador', displayName: 'motorista.documentoIdentificador', type: 'string', cellClass: 'text-center', headerCellClass: 'text-center'},
          {name: 'uoNome', displayName: 'ce.cadastro.motoristas.tabela.coluna.uo', type: 'string', enableHiding: false},
          {name: 'departamento', displayName: 'ce.cadastro.motoristas.tabela.coluna.departamento', type: 'string'},
          {
            name: 'telefone',
            displayName: 'ce.cadastro.motoristas.tabela.coluna.telefoneMotorista',
            type: 'number',
            cellFilter: 'brPhoneNumber'
          },
          {name: 'codigo', displayName: 'ce.cadastro.motoristas.tabela.coluna.codigo', type: 'number', cellClass: 'text-center', headerCellClass: 'text-center'},
          {name: 'ibutton', displayName: 'ce.cadastro.motoristas.tabela.coluna.iButton', type: 'string', cellClass: 'text-center', headerCellClass: 'text-center'},
          {name: 'tag', displayName: 'ce.cadastro.motoristas.tabela.coluna.rfid', type: 'number', cellClass: 'text-center', headerCellClass: 'text-center'},
          {name: 'formato', displayName: 'ce.cadastro.motoristas.tabela.coluna.formato', type: 'number', cellClass: 'text-center', headerCellClass: 'text-center'},
          {
            name: 'statusDocumento',
            displayName: 'ce.cadastro.motoristas.tabela.coluna.documentosDoMotorista',
            enableSorting: true,
            cellTemplate: `
            <div class='ui-grid-cell-contents status-documento {{ row.entity.statusDocumentoStyle }}'>
              <a ng-click='grid.appScope.redirectToDocumentos(row.entity.motoristaRow.id)' class="{{ row.entity.statusDocumentoStyle }}"
              title="{{ row.entity.statusDocumento }}">
                {{ row.entity.statusDocumento }}
              </a>
            </div>`,
            visible: this.featureFlag.DOCUMENTOS_MOTORISTA
          },
          {name: 'email', displayName: 'ce.cadastro.motoristas.tabela.coluna.email', type: 'string'},
          {name: 'pis', displayName: 'ce.cadastro.motoristas.tabela.coluna.pis', type: 'number', cellClass: 'text-center', headerCellClass: 'text-center'},
          {name: 'cnh', displayName: 'ce.cadastro.motoristas.tabela.coluna.cnh', type: 'number', cellClass: 'text-center', headerCellClass: 'text-center'},
          {
            name: 'vencimentoCnh',
            displayName: 'ce.cadastro.motoristas.tabela.coluna.vencimentoCnh',
            type: 'date',
            cellFilter: 'date:\'dd/MM/yyyy\'',
            filterCellFiltered: true
          },
          {
            name: 'ativo',
            displayName: 'ce.cadastro.motoristas.tabela.coluna.status.label',
            filter: {
              type: this.uiGridConstants.filter.SELECT,
              condition: this.uiGridConstants.filter.STARTS_WITH,
              selectOptions: [
                {value: true, label: this.ativos},
                {value: false, label: this.inativos}
              ]
            },
            type: 'boolean',
            cellTemplate: `
            <div
              class="ui-grid-cell-contents text-center">
              {{ grid.appScope.getStatus(row.entity.ativo) }}
            </div>`
          },
          {
            name: 'possuiFoto',
            displayName: this.possuiFoto.LABEL,
            type: 'boolean',
            cellClass: 'text-center',
            filter: {
              type: this.uiGridConstants.filter.SELECT,
              condition: this.uiGridConstants.filter.EXACT,
              selectOptions: [
                {value: true, label: this.possuiFoto.SIM},
                {value: false, label: this.possuiFoto.NAO}
              ]
            },
            visible: false,
            cellTemplate: `
            <div>
              {{ grid.appScope.getLabelPossuiFoto(row.entity.possuiFoto) }}
            </div>`
          },
          {
            name: 'editarCadastro',
            displayName: '',
            headerCellTemplate: `
            <span
              class="fa fa-info-circle info-editar-icon"
              uib-tooltip="{{ 'ce.cadastro.motoristas.tabela.coluna.tooltip' | translate }}"
              tooltip-placement="top-right">
            </span>`,
            enableFiltering: false,
            enableSorting: false,
            pinnedRight: true,
            enableColumnResizing: false,
            enableHiding: false,
            exporterSuppressExport: true,
            width: 55,
            cellTemplate: `
            <a ng-if="row.entity.permissaoEditar && grid.appScope.uosReplicadores.length === 0"
            id="motorista-id-{{row.entity.motoristaRow.id}}"
            ng-click='grid.appScope.modalEditar(row.entity.motoristaRow)'
            class="fa fa-pencil" ng-class="grid.appScope.showWarnPencil(row.entity.motoristaRow) ? 'icone-warn' : 'icone'"
            uib-tooltip="{{ grid.appScope.getTooltipEditMotorista(row.entity.motoristaRow) }}"></a>`
          },
          {
            name: 'cadastroDocumentos',
            displayName: '',
            enableFiltering: false,
            enableSorting: false,
            pinnedRight: true,
            enableColumnResizing: false,
            enableHiding: false,
            exporterSuppressExport: true,
            width: 55,
            cellTemplate: `
            <a ng-if="row.entity.ativo"
            uib-tooltip="{{ 'ce.cadastro.motoristas.tabela.coluna.documentosTooltip' | translate }}"
            ng-click='grid.appScope.redirectToDocumentos(row.entity.motoristaRow.id)'
            class="fa fa-file-text icone"></a>`,
            visible: this.featureFlag.DOCUMENTOS_MOTORISTA
          },
          {
            name: 'motoristaSync',
            displayName: '',
            enableFiltering: false,
            enableSorting: false,
            pinnedRight: true,
            enableColumnResizing: false,
            enableHiding: false,
            visible: this.isTTC,
            headerCellTemplate: `
            <span
              class="fa trimble-cloud-connected info-motorista-sync-icon"
            </span>`,
            exporterSuppressExport: true,
            width: 55,
            cellTemplate: `
            <a ng-if="row.entity.dateSync"
            uib-tooltip="{{ 'ce.cadastro.motoristas.tabela.coluna.ultimoUpdateTooltip' | translate: { lastSyncUpdate: row.entity.dateSync } }}"
            tooltip-placement="top-right"
            tooltip-class="custom-tooltip"
            class="fa trimble-cloud-connected icone"></a>`
          }
        ]
      };
    }

    insertQuandoFeatureAtiva(condition, element) {
      return condition ? [element] : [];
    }

    getTooltipEditMotorista(motorista) {
      return this.showWarnPencil(motorista) ? this.translate.instant('ce.cadastro.motoristas.tabela.coluna.editarCadastroTooltip') : '';
    }

    showWarnPencil(motorista) {
      return this.uoWithRecFacial && !motorista.allRequiredImagesValidated;
    }

    consultar() {
      const uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id,
          uoParceira = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uoParceira.name),
          solucaoId = this.featureFlag.SOLUCAO_REC_FACIAL ? this.solucoesEnum.RECONHECIMENTO_FACIAL : null;
      this.cadastroVeiculoService.hasRecFacial(uoId, solucaoId)
        .then(uoHasRecFacial => this.uoWithRecFacial = uoHasRecFacial);
      this.motoristaService.getTodosByUo(uoId, uoParceira ? uoParceira.value.id : '')
        .then((motoristas) => {
          this.motoristas = motoristas;
          this.getMotoristaUoReplicador();
        })
        .catch(() => this.vtabela.setError());
    }

    getStatus(ativo) {
      return ativo ? this.ativoUpperCase : this.inativoUpperCase;
    }

    getMotoristaUoReplicador() {
      let uosMotoristas = [...new Set(this.motoristas.map(motorista => motorista.uo.id))].join();
      this.middlewareService.getUosComIntegracaoMotoristas(uosMotoristas)
        .then(uosReplicadores => {
          this.uosReplicadores = uosReplicadores;
          this.configurarBotaoCadastroMotorista();
          this.setDataVtabela();
          this.uosCadastraveis = this.uosSemReplicador();
        })
        .catch(() => this.vtabela.setError());
    }

    setDataVtabela() {
      this.vtabela.setData(this.motoristas.map(item => this.getItemVtabela(item)));
    }

    getItemVtabela(item) {
      item.telefone = item.telefone ? item.telefone.replace(/\D/g, '') : null;
      return {
        id: item.id,
        permissao: item.permissao,
        nome: item.nome,
        departamento: item.departamento && item.departamento.nome,
        ibutton: item.ibutton,
        email: item.email,
        uo: item.uo,
        uoNome: item.uo.nome,
        cpf: item.cpf,
        documentoIdentificador: item.documentoIdentificador,
        tag: this.filter('hexa')(item.tag, item.tagHexadecimal),
        formato: Number(item.tagHexadecimal),
        codigo: item.codigo,
        pis: item.pis,
        cnh: item.cnh,
        vencimentoCnh: item.vencimentoCnh,
        ativo: item.ativo,
        permissaoEditar: this.isMotoristaEditavel(item.uo.id, item.permissao.tipo),
        motoristaRow: item,
        telefone: item.telefone,
        apelido: item.apelido,
        statusDocumento: item.statusDocumento && this.documentoStatus[item.statusDocumento].label,
        statusDocumentoStyle: item.statusDocumento && this.documentoStatus[item.statusDocumento].class,
        possuiFoto: this.hasFotoCadastro(item.fotos),
        dateSync: this.filter('date')(item.datetimeTtcSync, this.dateTimeFormat)
      };
    }

    hasFotoCadastro(fotos) {
      return Boolean(fotos && fotos.find(foto => foto.fotoCadastro && !foto.excluido && !foto.pretoBranco));
    }

    getLabelPossuiFoto(hasFoto) {
      return hasFoto ? this.possuiFoto.SIM : this.possuiFoto.NAO;
    }

    isMotoristaEditavel(uoId, permissao) {
      return !this.uosReplicadores.includes(uoId) &&
      this.roleCadastrar &&
      this.tipoPermissaoEnum.podeEditar(permissao);
    }

    rowsAreValid() {
      return this.motoristasEditaveis(true).length +
        this.motoristasEditaveis(false).length +
        this.motoristasComEmail().length > 0;
    }

    motoristasEditaveis(ativo) {
      if (this.vtabela) {
        let lista = this.vtabela.rowsSelected.length === 0 ? this.vtabela.grid.data : this.vtabela.rowsSelected;
        return lista.filter(m => m.permissao.tipo !== 'V' && m.ativo === ativo);
      }
      return [];
    }

    motoristasComEmail() {
      if (this.vtabela) {
        let lista = this.vtabela.rowsSelected.length === 0 ? this.vtabela.grid.data : this.vtabela.rowsSelected;
        return lista.filter(m => m.email);
      }
      return [];
    }

    uosSemReplicador() {
      return this.serviceVfiltro.filtroTopbar
        .find(x => x.key === this.keys.uo.name)
        .defaultValues
        .filter(x => !this.uosReplicadores.includes(x.id));
    }

    modalEditar(motorista) {
      /* eslint-disable */
      const botaoEditar = document.querySelector('#motorista-id-' + motorista.id),
          abrirModal = document.createElement('vfwc-abrir-editar-motorista'); /* eslint-enable */
      abrirModal.uoId = this.uoId;
      abrirModal.motorista = motorista;
      abrirModal.userPerfilId = this.userPerfilId;
      abrirModal.uoWithRecFacial = this.uoWithRecFacial;
      botaoEditar.innerHTML = '';
      botaoEditar.appendChild(abrirModal);
      this.onMotoristaSalvo(abrirModal);
    }

    replaceValue(motoristaEdited) {
      let motorista = this.motoristas.find(item => motoristaEdited.id === item.id);
      Object.keys(motoristaEdited).forEach(key => motorista[key] = motoristaEdited[key]);
      this.setDataVtabela();
    }

    ativarInativar(motorista) {
      if (motorista.ativo) {
        this.ativar([motorista]);
      } else {
        this.inativar([motorista]);
      }
    }

    confirmacaoAtivar(motoristas) {
      this.modalService.open({
        animation: true,
        templateUrl: 'cadastros/motorista/motorista-confirmacao.tpl.html',
        controller: 'MotoristaConfirmacaoCtrl',
        controllerAs: '$ctrl',
        resolve: {
          motoristas: () => motoristas,
          motoristaCtrl: () => this,
          ativando: () => true
        }
      });
    }

    ativar(motoristas) {
      this.motoristaService.ativar(motoristas.map(m => m.id))
        .then(result => {
          if (!result.success) {
            this.alertMessage.create({
              type: 'warning',
              message: this.translate.instant('ce.cadastro.motoristas.modal.confirmacao.erroAoAtivarMotoristas'),
              appendTo: '.alerta-aqui-motorista'
            });
          }
          motoristas.forEach((m, index) => {
            m.ativo = result.data[index].ativo;
          });
        });
    }

    confirmacaoInativar(motoristas) {
      this.modalService.open({
        animation: true,
        templateUrl: 'cadastros/motorista/motorista-confirmacao.tpl.html',
        controller: 'MotoristaConfirmacaoCtrl',
        controllerAs: '$ctrl',
        resolve: {
          motoristas: () => motoristas,
          motoristaCtrl: () => this,
          ativando: () => false
        }
      });
    }

    inativar(motoristas) {
      this.motoristaService.inativar(motoristas.map(m => m.id))
        .then(result => {
          if (!result.success) {
            this.alertMessage.create({
              type: 'warning',
              message: this.translate.instant('ce.cadastro.motoristas.modal.confirmacao.erroAoInativarMotoristas'),
              appendTo: '.alerta-aqui-motorista'
            });
          }
          motoristas.forEach((m, index) => {
            m.ativo = result.data[index].ativo;
          });
        });
    }

    abrirQrCode(motorista) {
      this.modalService.open({
        animation: true,
        templateUrl: 'cadastros/motorista/motorista-qrmodal.tpl.html',
        controller: 'ModalQrCtrl',
        controllerAs: '$ctrl',
        size: 'md',
        resolve: {
          motorista: () => motorista,
          motoristaCtrl: () => this
        }
      });
    }

    enviarEmails(motoristas) {
      this.modalService.open({
        animation: true,
        templateUrl: 'cadastros/motorista/motorista-emailmultiplomodal.tpl.html',
        controller: 'ModalEmailMultiploCtrl',
        controllerAs: '$ctrl',
        size: 'email',
        resolve: {
          motoristas: () => motoristas,
          httpService: () => this.httpService,
          uo: () => this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id,
          motoristaCtrl: () => this
        }
      });
    }

    getUrlCsv() {
      const uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id;
      let uoParceira = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uoParceira.name);

      if (uoParceira) {
        uoParceira = uoParceira.value.id;
      }

      return `/motorista/motoristas/${uoId}/csv?uoDona=${uoParceira ? uoParceira : ''}&timezone=${this.currentTimezone}`;
    }

    selecionouMotoristaInativo() {
      const selecionouMotoristaInativo = this.vtabela.rowsSelected.length === 1 && !this.vtabela.rowsSelected[0].ativo;
      return selecionouMotoristaInativo || this.vtabela.rowsSelected.length === 0;
    }

    hasMotoristasComEmail() {
      return this.motoristasComEmail().length > 0;
    }

    configurarBotaoCadastroMotorista() {
      /* eslint-disable */
      const btnCadastrarMotorista = document.querySelector('vfwc-btn-cadastro-motorista');
      if (this.roleCadastrar && !this.uosReplicadores.length && !btnCadastrarMotorista) {
        const elementContainer = document.querySelector('#botaoCadastrarMotorista'),
              btnCadastrarMotoristaElement = document.createElement('vfwc-btn-cadastro-motorista');
        /* eslint-enable */

        btnCadastrarMotoristaElement.uoId = Number(this.uoId);
        btnCadastrarMotoristaElement.uoWithRecFacial = this.uoWithRecFacial;
        elementContainer.appendChild(btnCadastrarMotoristaElement);
        this.onMotoristaSalvo(btnCadastrarMotoristaElement);
      }
    }

    onMotoristaSalvo(element) {
      element.addEventListener('emiteSucesso', (event) => {
        if (event.detail && event.detail.salvoComSucesso) {
          this.consultar();
        }
      });
    }

    redirectToDocumentos(motoristaId) {
      const uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id;
      this.sendClickGTM();
      this.state.go('root.documentos', {
        vfiltro: `motoristas:${motoristaId};uo:${uoId}`,
        podeEditar: this.roleCadastrar && this.uosReplicadores.length === 0
      });
    }

    disableAtivarInativar(length) {
      return (length > 1 || length === 0) && !this.isAdminVeltec;
    }

    sendClickGTM() {
      if (this.user) {
        this.googleTagManagerUtils.sendEventClickGTM('motorista_ver_documento', this.user);
      }
    }
  }

  class MotoristaConfirmacao {
    constructor($uibModalInstance, motoristas, motoristaCtrl, ativando) {
      this.motoristas = motoristas;
      this.modalInstance = $uibModalInstance;
      this.motoristaCtrl = motoristaCtrl;
      this.ativando = ativando;
    }

    ok() {
      this.modalInstance.close();
    }

    cancel() {
      this.modalInstance.dismiss();
    }

    mostrarTexto() {
      return this.ativando ?
        'ce.cadastro.motoristas.modal.confirmacao.msgAtivar' :
        'ce.cadastro.motoristas.modal.confirmacao.msgInativar';
    }

    getTitle() {
      return this.ativando ?
        'ce.cadastro.motoristas.modal.confirmacao.tituloAtivando' :
        'ce.cadastro.motoristas.modal.confirmacao.tituloInativando';
    }

    confirmacaoAtivar() {
      this.motoristaCtrl.ativar(this.motoristas);
      this.ok();
    }

    confirmacaoInativar() {
      this.motoristaCtrl.inativar(this.motoristas);
      this.ok();
    }
  }

  class MotoristaQRCode {
    constructor($uibModalInstance, motorista, motoristaCtrl, UrlConfigService, MotoristaService) {
      this.motorista = motorista;
      this.modalInstance = $uibModalInstance;
      this.motoristaCtrl = motoristaCtrl;
      this.urlConfigService = UrlConfigService;
      this.motoristaService = MotoristaService;

      this.qrCodeImage = null;
      this.init();
    }

    init() {
      this.motoristaService.getQRCode(this.motorista.uo.id, this.motorista.id).then((result) => {
        this.qrCodeImage = `data:image/png;base64,${result.qrCodeBase64}`;
      });
    }

    ok() {
      this.modalInstance.close();
    }

    cancel() {
      this.modalInstance.dismiss();
    }

    download() {
      const anchor = angular.element('<a/>');
      anchor.attr({
        href: this.qrCodeImage,
        target: '_blank',
        download: `qrcode-uo-${this.motorista.uo.id}-motorista-${this.motorista.id}.png`
      })[0].click();
    }
  }

  class MotoristaEmailMultiplo {
    constructor($uibModalInstance, motoristas, httpService, uo, motoristaCtrl) {
      this.motoristas = motoristas;
      this.modalInstance = $uibModalInstance;
      this.httpService = httpService;
      this.enviando = false;
      this.uo = uo;
      this.result = false;
      this.motoristaCtrl = motoristaCtrl;
    }

    ok() {
      this.modalInstance.close();
    }

    cancel() {
      this.modalInstance.dismiss();
    }

    enviarEmails() {
      this.enviando = true;
      this.httpService.post('/motorista/motoristas/' + this.uo + '/email', this.motoristas.map(m => m.id))
        .success(data => {
          this.result = data;
          this.result.message = 'ce.cadastro.motoristas.modal.email.emailsEstaoSendoEnviados';
          this.enviando = false;
        })
        .error(data => {
          this.result = data;
          this.result.message = 'ce.cadastro.motoristas.modal.email.erroAoEnviarEmails';
          this.enviando = false;
        });
    }
  }

  /**
   * @ngdoc object
   * @name cadastros.motorista.controller:MotoristaCtrl
   *
   * @description
   *
   */
  angular
    .module('cadastros.motorista')
    .controller('MotoristaCtrl', MotoristaCtrl)
    .controller('ModalQrCtrl', MotoristaQRCode)
    .controller('ModalEmailMultiploCtrl', MotoristaEmailMultiplo)
    .controller('MotoristaConfirmacaoCtrl', MotoristaConfirmacao);
}());
