/* eslint-disable angular/log */
(function () {
  'use strict';

  class ConfigEventosCtrl {
    constructor($stateParams, Authenticator, SolucoesEnum, FeatureFlag, FeatureOuService) {
      this.authenticator = Authenticator;
      this.solucoesEnum = SolucoesEnum;
      this.hasPermissionOcorrenciaSolucao = false;
      this.hasPermissaoConfigPesoEventosSolucao = false;
      this.hasPermissaoConfigDescarteEventosSolucao = false;
      this.hasPermissaoConfigTurno = false;
      this.hasPermissionConfigAlerta = false;
      this.FeatureOuService = FeatureOuService;
      this.shouldDisplayWorkShift = false;
      this.tipoSubpagina = {
        TIPOS: 'tipos',
        TURNOS: 'turnos',
        OCORRENCIAS: 'ocorrencias',
        PESOS: 'pesos',
        DESCARTE: 'descarte'
      };
      this.featureFlag = FeatureFlag;
      this.tipo = this.getTipo($stateParams.tipoSubpagina && $stateParams.tipoSubpagina.toLowerCase());
      this.setUserParams();
    }

    setUserParams() {
      this.authenticator.getUser().then((user) => {
        this.hasPermissionConfigAlerta = user.permissoesSolucao.includes('CONFIGURACAO_ALERTA_VISUALIZAR');
        this.hasPermissionOcorrencia = user.permissoesSolucao.includes('CONFIG_OCORRENCIA_VISUALIZAR');
        this.hasPermissionOcorrenciaSolucao = user.permissoesSolucao.indexOf('CONFIG_OCORRENCIA_VISUALIZAR') !== -1;
        this.hasPermissaoConfigPesoEventosSolucao = user.permissoesSolucao.indexOf('CONFIG_PESO_EVENTO_VISUALIZAR') !== -1;
        this.hasPermissaoConfigDescarteEventosSolucao = user.permissoesSolucao.indexOf('CONFIG_DESCARTE_VISUALIZAR') !== -1;
        this.hasPermissaoConfigTurno = user.permissoesSolucao.includes('ONE_PAGE_VISUALIZAR') || user.permissoesSolucao.includes('CONFIG_OCORRENCIA_VISUALIZAR');
        this.getEventsConfig(user.uo.id);
      });
    }

    getTipo(tipo) {
      switch (tipo) {
        case this.tipoSubpagina.TURNOS:
          return this.tipoSubpagina.TURNOS;
        case this.tipoSubpagina.PESOS:
          return this.tipoSubpagina.PESOS;
        case this.tipoSubpagina.OCORRENCIAS:
          return this.tipoSubpagina.OCORRENCIAS;
        case this.tipoSubpagina.DESCARTE:
          return this.tipoSubpagina.DESCARTE;
        default:
          return this.tipoSubpagina.TIPOS;
      }
    }
    getEventsConfig(userUoId) {
      return this.FeatureOuService.getEventsConfig(userUoId).then((eventNaConfig) => {
        this.shouldDisplayWorkShift = eventNaConfig;
      }).catch((error) => {
        console.error('Error loading featureOu config: ', error);
      });
    }
  }

  angular
    .module('configEventos')
    .controller('ConfigEventosCtrl', ConfigEventosCtrl);
}());
