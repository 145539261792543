/* global document */
(function () {
  'use strict';

  class PontoReferencia {
    constructor($httpParamSerializer, $uibModal, AlertMessage, PontosReferencia, CategoriaPontosReferencia,
                v3gTableLimitService, ValidateUtil, Keys, ServiceVfiltro, FactoryVfiltro,
                ObjetosVfiltroService, VtabelaFactory, uiGridConstants, TipoPermissaoEnum, $translate, Authenticator, FeaturehubJsSdk, FeatureHubConfigKeys) {
      this.httpParamSerializer = $httpParamSerializer;
      this.alertMessage = AlertMessage;
      this.pontosReferenciaService = PontosReferencia;
      this.pontosCategoriaReferenciaService = CategoriaPontosReferencia;
      this.v3gTableLimitService = v3gTableLimitService;
      this.validateUtil = ValidateUtil;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.modalService = $uibModal;
      this.tipoPermissaoEnum = TipoPermissaoEnum;
      this.translate = $translate;
      this.authenticator = Authenticator;
      this.isIntegrationCodeLandmarkEnabled = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.ENABLE_INTEGRATION_CODE_LANDMARK);
      this.FIELDS_PONTOS_REFERENCIA = [
        'id',
        'nome',
        'categoriaNome',
        'uo.nome',
        'localizacao',
        'logradouro',
        'lat',
        'lng',
        'raio',
        'obs',
        'hasRotograma',
        'tempoPermanenciaMinimo',
        'tipoPermissao',
        'codigoIntegracao'
      ];
      this.USUARIOS_IMPORTACAO = {
        'ana_padua@trimble.com': true,
        'daniel_silva@trimble.com': true,
        'gabriel_giovani@trimble.com': true,
        'gustavo_paiva@trimble.com': true,
        'jean_camillo@trimble.com': true,
        'joao_barroso@trimble.com': true,
        'jose_neto@trimble.com': true,
        'leandro_soares@trimble.com': true,
        'marcos_nakamura@trimble.com': true,
        'rafael_arabori@trimble.com': true,
        'renan_rodrigues@trimble.com': true
      };
      this.usuarioLogado = null;
      this.authenticator.getUser().then(user => {
        this.usuarioLogado = user.login;
        this.uoId = user ? user.uo.id : null;
      });

      this.vtabela = null;
      this.tableState = this.objetosVfiltroService.getTableStateUrl();

      ServiceVfiltro.init(FactoryVfiltro.get([]))
      .then(() => {
        this.roleVisualizarMapa = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('LOCALIZACAO_ATUAL_VISUALIZAR');
        this.roleVisualizarPonto = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('PONTO_REFERENCIA_VISUALIZAR');
        this.roleEditarPontoReferencia = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('PONTO_REFERENCIA_EDITAR');
        this.roleEditarPermanenciaPonto = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('PERMANENCIA_PONTO_REFERENCIA_EDITAR');
        this.carregarPontosReferencia();
      });
    }

    getMaisOpcoesMsg() {
      if (!this.roleEditarPontoReferencia) {
        return this.translate.instant('ce.cadastro.pontosReferencia.msgSemPermissao');
      }

      if (!this.getEditableRowsSelected().length) {
        return this.translate.instant('ce.cadastro.pontosReferencia.msgSelecionePontoPermissao');
      }

      return null;
    }

    initVtabela() {
      const exporterBaseName = this.translate.instant('ce.cadastro.pontosReferencia.exporter');
      let objVtabela = {
        exporterPdfFilename: `${exporterBaseName}.pdf`,
        exporterCsvFilename: `${exporterBaseName}.csv`,
        exporterExcelFilename: `${exporterBaseName}.xlsx`,
        appScopeProvider: this,
        columnDefs: [
            {
              name: 'id',
              displayName: 'Id',
              type: 'string',
              enableHiding: Boolean(this.USUARIOS_IMPORTACAO[this.usuarioLogado]),
              visible: false
            },
            {
              name: 'nome',
              displayName: 'ce.cadastro.pontosReferencia.colunas.nome',
              type: 'string',
              pinnedLeft: true,
              enableHiding: false,
              cellTemplate:
              `<div class="ui-grid-cell-contents">
                <i
                  ng-if="!grid.appScope.tipoPermissaoEnum.podeEditar(row.entity.tipoPermissao)"
                  class="fa fa-minus-circle"
                  uib-tooltip="{{ 'ce.cadastro.pontosReferencia.tooltipSemPermissao' | translate }}">
                </i>
                <span ng-class='{"no-icon": grid.appScope.tipoPermissaoEnum.podeEditar(row.entity.tipoPermissao)}'>
                  {{row.entity.nome}}
                </span>
              </div>`
            },
            {
              name: 'categoriaNome',
              displayName: 'ce.cadastro.pontosReferencia.colunas.categoria',
              type: 'string',
              cellFilter: 'withLabelToUndefined:\'-\'',
              enableHiding: false,
              pinnedLeft: true
            },
            {
              name: 'uoNome',
              displayName: 'ce.cadastro.pontosReferencia.colunas.uo',
              type: 'string',
              enableHiding: false
            },
            {
              name: 'localizacao',
              displayName: 'ce.cadastro.pontosReferencia.colunas.localizacao',
              type: 'string'
            },
            {
              name: 'logradouro',
              displayName: 'ce.cadastro.pontosReferencia.colunas.logradouro',
              type: 'string',
              cellTemplate: `
              <div class='ui-grid-cell-contents' title="{{row.entity.logradouro}}">
                <a ng-href='{{(row.entity.link)}}' target='_blank' ng-if='grid.appScope.roleVisualizarMapa && grid.appScope.roleVisualizarPonto'>
                  {{ grid.appScope.filtroLogradouro(row.entity.logradouro)}}
                </a>
                <span ng-if='!grid.appScope.roleVisualizarMapa && !grid.appScope.roleVisualizarPonto'> {{row.entity.logradouro}} </span>
                <span ng-if='!grid.appScope.roleVisualizarMapa && grid.appScope.roleVisualizarPonto'> {{row.entity.logradouro}} </span>
              </div>
              `
            },
            {
              name: 'raio',
              displayName: 'ce.cadastro.pontosReferencia.colunas.raio',
              type: 'number',
              cellClass: 'text-center',
              headerCellClass: 'text-center'
            },
            {
              name: 'coordenada',
              displayName: 'ce.cadastro.pontosReferencia.colunas.coordenada',
              type: 'string',
              cellClass: 'text-center',
              headerCellClass: 'text-center'
            },
            {
              name: 'observacao',
              displayName: 'ce.cadastro.pontosReferencia.colunas.observacao',
              type: 'string'
            },
            {
              name: 'hasRotograma',
              displayName: 'ce.cadastro.pontosReferencia.colunas.rotograma.displayName',
              type: 'string',
              enableFiltering: false,
              enableColumnResizing: false,
              width: 100,
              cellTemplate: `
              <div class='ui-grid-cell-contents text-center'>
                <span
                  ng-if='row.entity.hasRotograma' class='fa fa-check'
                  title="{{ 'ce.cadastro.pontosReferencia.colunas.rotograma.content' | translate }}"/>
              </div>
              `
            },
            {
              name: 'tempoPermanenciaMinimo',
              displayName: 'ce.cadastro.pontosReferencia.colunas.tempoPermanencia',
              filterCellFiltered: true,
              cellFilter: 'humanizedOrUndefinedWithLabel:\'-\'',
              cellClass: 'text-center'
            },
            {
              name: 'codigoIntegracao',
              displayName: 'ce.cadastro.pontosReferencia.colunas.codigoIntegracao',
              type: 'string',
              enableHiding: this.isIntegrationCodeLandmarkEnabled,
              visible: false
            }
        ]
      };

      return objVtabela;
    }

    getUrlCsv() {
      return `/ponto-referencia/v2/pontos-referencia/csv`;
    }

    getItemVtabela(item) {
      return {
        id: item.id,
        nome: item.nome,
        latitude: item.lat,
        longitude: item.lng,
        raio: item.raio,
        uoNome: item.uo.nome,
        localizacao: item.localizacao,
        logradouro: item.logradouro,
        observacao: item.obs,
        categoriaNome: item.categoriaNome,
        coordenada: item.lat.toString().replace('.', ',') + ', ' + item.lng.toString().replace('.', ','),
        link: `/mapas-grid?ponto=${item.id}&forceRenderLayer=true`,
        hasRotograma: item.hasRotograma,
        tempoPermanenciaMinimo: item.tempoPermanenciaMinimo,
        tipoPermissao: item.tipoPermissao,
        codigoIntegracao: item.codigoIntegracao
      };
    }

    carregarPontosReferencia() {
      this.pontosReferenciaService.getPontosReferenciaV2({
        fields: this.FIELDS_PONTOS_REFERENCIA.toString()
      })
      .then(data => {
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'cadastroPontos', 'v2');
        this.vtabela.setData(data.map(i => this.getItemVtabela(i)));
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.cadastro.pontosReferencia.errorMessage'),
          appendTo: '.alerta-aqui-ponto'
        });
      });
    }

    abrirTempoPermanenciaConfig(pontos, remove = false) {
      return this.modalService.open({
        animation: true,
        templateUrl: `cadastros/ponto-referencia/tempo-permanencia/tempo-permanencia${remove ? '-remove' : ''}.tpl.html`,
        controller: 'TempoPermanenciaCtrl',
        controllerAs: '$ctrl',
        backdrop: 'static',
        resolve: {
          pontos: () => pontos
        }
      })
      .result
      .then(values => this.rebuildPontos(values.data, remove))
      .catch(erro => {
        if (erro && erro.msg) {
          this.alertMessage.create({
            type: 'error',
            message: erro.msg,
            appendTo: '.alerta-aqui'
          });
        }
      });
    }

    rebuildPontos(pontosAtualizados, remove) {
      if (pontosAtualizados.length > 0) {
        pontosAtualizados.forEach(p => {
          let itemTabela = this.vtabela.grid.data.find(x => x.id === p.id);
          if (itemTabela) {
            angular.merge(itemTabela, {
              tempoPermanenciaMinimo: p.tempoPermanenciaMinimo
            });
          }
        });
        this.alertMessage.create({
          type: 'success',
          message: this.translate.instant('ce.cadastro.pontosReferencia.successMessage',
            {
              msg: remove ?
                this.translate.instant('ce.cadastro.pontosReferencia.removida') :
                this.translate.instant('ce.cadastro.pontosReferencia.cadastrada')
            }),
          appendTo: '.alerta-aqui'
        });
      }
    }

    filtroLogradouro(logradouro) {
      return !this.validateUtil.temLogradouro(logradouro) ? this.translate.instant('ce.cadastro.pontosReferencia.verNoMapa') : logradouro;
    }

    getEditableRowsSelected() {
      if (!this.vtabela || !this.vtabela.rowsSelected) {
        return [];
      }
      return this.vtabela.rowsSelected.filter(i => this.tipoPermissaoEnum.podeEditar(i.tipoPermissao));
    }

    podeExibirOpcoes() {
      return this.getEditableRowsSelected().length > 0 && this.roleEditarPermanenciaPonto;
    }

    podeExibirOpcoesImportacao() {
      return Boolean(this.USUARIOS_IMPORTACAO[this.usuarioLogado]);
    }

    abrirModalImportacao(pontos) {
      /* eslint-disable */
      const botaoImportar = document.querySelector('#abrirModal'),
          abrirModal = document.createElement('vfwc-abrir-importacao-ponto-modal'); /* eslint-enable */
      abrirModal.uoIdUsuario = this.uoId;
      abrirModal.pontos = pontos;
      botaoImportar.appendChild(abrirModal);
    }

    abrirCodigoIntegracaoCadastro(pontos, remove = false) {
      return this.modalService.open({
        animation: true,
        templateUrl: `cadastros/ponto-referencia/codigo-integracao/codigo-integracao${remove ? '-remove' : ''}.tpl.html`,
        controller: 'CodigoIntegracaoCtrl',
        controllerAs: '$ctrl',
        backdrop: 'static',
        resolve: {
          pontos: () => pontos
        }
      })
      .result
      .then(values => this.rebuildPontosCodigoIntegracao(values.data, remove))
      .catch(erro => {
        if (erro && erro.msg) {
          this.alertMessage.create({
            type: 'error',
            message: erro.msg,
            appendTo: '.alerta-aqui'
          });
        }
      });
    }

    rebuildPontosCodigoIntegracao(pontosAtualizados, remove) {
      if (pontosAtualizados.length > 0) {
        pontosAtualizados.forEach(p => {
          let itemTabela = this.vtabela.grid.data.find(x => x.id === p.id);
          if (itemTabela) {
            angular.merge(itemTabela, {
              codigoIntegracao: p.codigoIntegracao
            });
          }
        });
        this.alertMessage.create({
          type: 'success',
          message: this.translate.instant('ce.cadastro.pontosReferencia.successCodigoIntegracaoMessage',
            {
              msg: remove ?
                this.translate.instant('ce.cadastro.pontosReferencia.removido') :
                this.translate.instant('ce.cadastro.pontosReferencia.cadastrado')
            }),
          appendTo: '.alerta-aqui'
        });
      }
    }
  }

  angular
    .module('cadastros.pontoReferencia')
    .controller('PontoReferenciaCtrl', PontoReferencia);
}());
