(function () {
  'use strict';

  /* global _, Intl, document */
  class HistoricoAlertaCtrl {
    constructor($uibModal, $window, $httpParamSerializer, $sce, ConvertersService,
      RelatorioAlerta, moment, v3gTableLimitService, ValidateUtil, VtabelaFactory,
      Keys, ServiceVfiltro, FactoryVfiltro, UrlService, ObjetosVfiltroService, Authenticator,
      $filter, AlertasEnum, StatusVideo, MultiVideoService, $translate, GoogleTagManagerUtils, Uos, MeasurementUnitConverterService,
      FeaturehubJsSdk, FeatureHubConfigKeys
    ) {
      this.uibModal = $uibModal;
      this.window = $window;
      this.convertersService = ConvertersService;
      this.httpParamSerializer = $httpParamSerializer;
      this.sce = $sce;
      this.relatorioAlertaService = RelatorioAlerta;
      this.moment = moment;
      this.v3gTableLimitService = v3gTableLimitService;
      this.validateUtil = ValidateUtil;
      this.vtabelaFactory = VtabelaFactory;
      this.multiVideoService = MultiVideoService;
      this.uoService = Uos;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.authenticator = Authenticator;
      this.uoIdUsuarioLogado = null;
      this.featureFlagPremiumTelemetry = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.FEATURE_PREMIUM_TELEMETRY);
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;

      this.urlService = UrlService;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.filter = $filter;
      this.alertasEnum = AlertasEnum;
      this.alertasExpurgo = AlertasEnum.getTiposExpurgo();
      this.statusVideo = StatusVideo;
      this.translate = $translate;
      this.labelSemMotorista = this.translate.instant('ce.analise.alertas.colunas.motorista.semIdentificacao');
      this.tooltipNaoAplica = this.translate.instant('ce.analise.alertas.colunas.excessoTooltipNaoAplica');
      this.tooltipDias = this.translate.instant('ce.analise.alertas.colunas.excessoTooltipDias');
      this.semVideo = this.translate.instant('ce.analise.alertas.colunas.video.semVideo');
      this.semFoto = this.translate.instant('ce.analise.alertas.colunas.video.semFoto');
      this.solicitarVideo = this.translate.instant('ce.analise.alertas.colunas.video.solicitarVideo');
      this.solicitarImagem = this.translate.instant('ce.analise.alertas.colunas.video.solicitarImagem');
      this.processando = this.translate.instant('ce.analise.alertas.colunas.video.processando');
      this.videoNaoDisponivel = this.translate.instant('ce.analise.alertas.colunas.video.tooltipNaoDisponivel');
      this.semSinal = this.translate.instant('ce.enum.common.semSinal');
      this.abonarEvento = this.translate.instant('ce.analise.alertas.colunas.abonarEvento');
      this.cliqueParaVer = this.translate.instant('ce.analise.alertas.detalhes.cliqueParaVerTooltip');
      this.currentTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

      this.tableState = this.objetosVfiltroService.getTableStateUrl();
      this.vtabela = null;
      this.exportsPdf = false;
      this.exibirCampoExcesso = true;
      this.exibirCampoDuracao = true;
      this.exibirCampoAbono = false;
      this.trusted = {};
      this.tooltipSemSinal = {};
      this.options = {
        10: '10',
        20: '20',
        50: '50'
      };
      this.status = {
        NOVO: {label: this.translate.instant('ce.analise.alertas.status.novo'), cor: 'bg-green'},
        RESOLVIDO: {label: this.translate.instant('ce.analise.alertas.status.resolvido'), cor: 'bg-blue'},
        ATRIBUIDO: {label: this.translate.instant('ce.analise.alertas.status.atribuido'), cor: 'bg-orange'}
      };
      this.nivel = {
        BAIXO: {label: this.translate.instant('ce.analise.alertas.nivel.baixo'), cor: 'bg-blue'},
        MEDIO: {label: this.translate.instant('ce.analise.alertas.nivel.medio'), cor: 'bg-yellow'},
        ALTO: {label: this.translate.instant('ce.analise.alertas.nivel.alto'), cor: 'bg-red'}
      };
      this.trusted = {};
      this.stItemsByPage = 10;
      this.dataPreprocessada = this.moment('2017-10-01T00:00:00-0300');
      this.maxCsvSize = 40000;

      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.authenticator.getUser().then(user => {
        this.uoIdUsuarioLogado = user ? user.uo.id : null;
        this.dateTimeFormat = this.measurementUnitConverterService.getDateTimeFormat(user.measurementUnits.dateTimeFormat);
        this.speedMeasurement = user.measurementUnits.speedMeasurement;
      });

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          dateLimit: 1,
          time: true,
          maxDate: this.moment().endOf('day').format(),
          defaultValues: {
            hoje: {
              active: true
            },
            ontem: {
              active: true
            }
          }
        },
        {
          key: this.keys.uo.name,
          size: 12
        },
        {
          name: 'uoParceira',
          key: this.keys.uoParceira.name,
          size: 6
        },
        {
          name: 'veiculo',
          key: this.keys.veiculo.name,
          size: 6
        },
        {
          key: this.keys.status.name,
          size: 6
        },
        {
          key: this.keys.nivel.name,
          size: 6
        },
        {
          key: this.keys.tipos.name,
          size: 12
        },
        {
          key: this.keys.usuario.name,
          size: 6
        },
        {
          key: this.keys.feedbackVideo.name,
          size: 6
        },
        {
          key: this.keys.eventoTic.name,
          size: 6
        },
        {
          key: this.keys.statusVideos.name,
          size: 12
        }
      ]))
      .then(() => {
        this.isAdminVeltec = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('ADMIN_VELTEC');
        this.roleAbonoEditar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('ABONO_EDITAR');
        this.roleCadastrar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('HISTORICO_ALERTA_EDITAR');
        this.roleHistoricoDetalhado = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('HISTORICO_DETALHADO_VISUALIZAR');
        this.roleVisualizarVideo = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('VIDEO_VISUALIZAR');
        this.roleSolicitarVideo = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('VIDEO_EDITAR');

        this.exibirCampoAbono = this.roleAbonoEditar;
        this.iniciaTabelaConsulta();
      });

      this.onUpdateVideo();
    }

    iniciaTabelaConsulta() {
      this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'historicoAlerta', 'v3');
      this.consultar();
    }

    getMaisOpcoesMsg() {
      if (this.vtabela.rowsSelected.length === 0) {
        return this.translate.instant('ce.analise.alertas.opcoes.msg');
      }
    }

    onUpdateVideo() {
      this.multiVideoService.sharedScope.$on('update-video', (_, video) => {
        const alertasComVideoASerAtualizado = this.vtabela.grid.data.filter(alerta => alerta.videoId === video.id);
        alertasComVideoASerAtualizado.forEach(alerta => {
          alerta.statusVideo = video.state;
          alerta.usuarioEmailAssistiuVideo = video.userLogin;
          if (angular.isUndefined(alerta.feedback) && angular.isDefined(video.feedbackAnswer)) {
            alerta.feedback = Boolean(video.feedbackAnswer);
            alerta.usuarioFeedbackEmail = video.userLogin;
          }
        });
      });
    }

    initVtabela() {
      return {
        virtualizationThreshold: 150,
        columnVirtualizationThreshold: 99,
        enableVtabelaExport: false,
        enableFiltering: false,
        useExternalPagination: true,
        useExternalSorting: true,
        paginationCurrentPage: 1,
        appScopeProvider: this,
        getPage: (limit, offset, orderBy, orderDirection) => {
          const uo = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id;
          return this.relatorioAlertaService.get(uo, this.getParams(limit, offset, orderBy, orderDirection)).then(
            dat => {
              this.vtabela.grid.totalItems = dat.total;
              this.vtabela.setData(dat.list.map(item => this.getItemVtabela(item)));
              this.vtabela.rowsSelected = [];
            }
          );
        },
        exporterPdfFilename: 'Histórico de Alertas PDF.pdf',
        exporterCsvFilename: 'Histórico de Alertas CSV.csv',
        exporterExcelFilename: 'Histórico de Alertas XLSX.xlsx',
        columnDefs: [
          {
            name: 'expurgo',
            displayName: 'ce.analise.alertas.colunas.abono',
            pinnedLeft: false,
            enableHiding: this.exibirCampoAbono,
            enableColumnResizing: false,
            width: 30,
            enableSorting: false,
            visible: false,
            headerCellClass: 'abono-header data-testid=alert-vtabela-header-abono',
            cellTemplate: `
            <div class='ui-grid-cell-contents'>
              <span ng-if='grid.appScope.exibirCampoAbono && row.entity.temPermissaoVeiculo && grid.appScope.verificaSeTipoExpurgo(row.entity.tipo)' data-testid=alert-vtabela-abono-{{row.entity.placa}}>
                <a
                  uib-tooltip="{{ grid.appScope.abonarEvento }}"
                  class="btn-solicitar-video-foto"
                  ng-click='grid.appScope.abrirModalAbonarEvento(row.entity)'
                  id="abrirModalAbono">
                  <span class="material-icons icone-abonar-evento">
                    delete_outline
                  </span>
                </a>
              </span>
            </div>`
          },
          {
            name: 'placa',
            displayName: 'ce.analise.alertas.colunas.placa',
            pinnedLeft: true,
            enableHiding: false,
            enableColumnResizing: false,
            enableSorting: false,
            headerCellClass: 'data-testid=alert-vtabela-header-placa',
            cellClass: 'data-testid=alert-vtabela-placa-cell'
          },
          {
            name: 'prefixo',
            displayName: 'ce.analise.alertas.colunas.prefixo',
            enableHiding: false,
            enableSorting: false,
            headerCellClass: 'data-testid=alert-vtabela-header-prefixo',
            cellClass: 'data-testid=alert-vtabela-prefixo-cell'
          },
          {
            name: 'grupo',
            displayName: 'ce.analise.alertas.colunas.grupo',
            enableSorting: false,
            headerCellClass: 'data-testid=alert-vtabela-header-grupo',
            cellClass: 'data-testid=alert-vtabela-grupo-cell'
          },
          {
            name: 'motorista',
            displayName: 'ce.analise.alertas.colunas.motorista.motoristaLabel',
            enableSorting: false,
            headerCellClass: 'data-testid=alert-vtabela-motorista-header',
            cellClass: 'data-testid=alert-vtabela-motorista-cell',
            cellTemplate: this.getMotoristaCellTemplate()
          },
          {
            name: 'uo',
            displayName: 'ce.analise.alertas.colunas.uo',
            enableHiding: false,
            enableSorting: false,
            headerCellClass: 'data-testid=alert-vtabela-uo-header',
            cellClass: 'data-testid=alert-vtabela-uo-cell'
          },
          {
            name: 'tipo',
            displayName: 'ce.analise.alertas.colunas.tipo',
            enableHiding: false,
            headerCellClass: 'data-testid=alert-vtabela-tipo-header',
            cellTemplate: `
            <div class='ui-grid-cell-contents' title={{grid.appScope.getDescricao(row.entity.tipo)}}>
              <a
                class='action-link'
                ng-if='grid.appScope.hasLinkPortalPassivelManutencao(row.entity)'
                uib-popover-html="grid.appScope.setPopoverContentVeiculoSemSinal(row.entity)"
                popover-title="{{ grid.appScope.semSinal }}"
                popover-trigger="'focus'"
                popover-placement="top"
                tabindex="0"
                data-testid=alert-vtabela-tipo-{{row.entity.placa}} >
                {{ grid.appScope.getDescricao(row.entity.tipo) }}
              </a>
              <span ng-if='!grid.appScope.hasLinkPortalPassivelManutencao(row.entity)' data-testid=alert-vtabela-tipo-{{row.entity.placa}}>
                {{ grid.appScope.getDescricao(row.entity.tipo) }}
              </span>
              <div class='div-icone'
              ng-if='grid.appScope.verificaSeGTP(row.entity.meio)'>
                <span class="material-icons icone-tic"
                uib-tooltip="{{ 'ce.analise.alertas.colunas.alertaGTP' | translate }}"
                tooltip-placement="top">
                hub
                </span>
              </div>
              <span class="material-icons info-icon"
                ng-if='grid.appScope.isEventTooltip(row.entity.tipo)'
                uib-tooltip="{{ grid.appScope.getEventTooltip(row.entity.tipo) | translate }}"
                tooltip-placement="top">
                info
              </span>
            </div>`
          },
          {
            name: 'limiar',
            displayName: 'ce.analise.alertas.colunas.limiar',
            enableSorting: false,
            headerCellClass: 'text-center data-testid=alert-vtabela-limiar-header',
            cellClass: 'text-center data-testid=alert-vtabela-limiar-cell'
          },
          {
            name: 'excesso',
            displayName: 'ce.analise.alertas.colunas.excesso',
            enableSorting: !!this.serviceVfiltro.factoryVfiltro.filtroUrl.tipos,
            cellClass: 'text-center',
            headerCellClass: 'text-center data-testid=alert-vtabela-excesso-header',
            cellTemplate: `
            <div class='ui-grid-cell-contents text-center'>
              <div ng-if="row.entity.excesso === '0.00 G'
                || row.entity.excesso === '0 Km/h/s'
                || row.entity.excesso === '0 Km/h'"
                uib-tooltip='{{ grid.appScope.toolTipNaoAplica }}' tooltip-placement='left'> N/A </div>
              <div ng-if="row.entity.excesso !== '0.00 G'
                && row.entity.excesso !== '0 Km/h/s'
                && row.entity.excesso !== '0 Km/h'">
                <span class='badge text-center'
                  ng-if="row.entity.tipo === 'VEICULO_SEM_SINAL'"
                  tooltip-placement='left'
                  uib-tooltip='{{ grid.appScope.tooltipDias }}'
                  ng-class='grid.appScope.nivel[row.entity.nivel].cor'
                  data-testid=alert-vtabela-excesso-{{row.entity.placa}}>
                  {{ row.entity.excesso }}
                </span>
                <span class='badge'
                  role="button"
                  ng-class='grid.appScope.nivel[row.entity.nivel].cor'
                  ng-if="row.entity.tipo === 'DIAGNOSTICO_OBD'"
                  uib-popover-html="grid.appScope.setPopoverContent(
                    row.entity.excesso,
                    row.entity.miscInfo.obdDesc,
                    row.entity.miscInfo.origem === 'OBD')"
                  popover-title="{{row.entity.excesso}}"
                  popover-trigger="'focus'"
                  popover-placement="right"
                  tabindex="0"
                  data-testid=alert-vtabela-excesso-{{row.entity.placa}}>
                  {{ row.entity.excesso }}
                </span>
                <span class='badge'
                  ng-class='grid.appScope.nivel[row.entity.nivel].cor'
                  ng-if="row.entity.tipo !== 'DIAGNOSTICO_OBD' && row.entity.tipo !== 'VEICULO_SEM_SINAL'"
                  uib-tooltip="{{grid.appScope.getTooltipMsgExcesso(row)}}" tooltip-placement="left"
                  data-testid=alert-vtabela-excesso-{{row.entity.placa}}>
                  {{ row.entity.excesso }}
                </span>
              </div>
            </div>`
          },
          {
            name: 'duracao',
            displayName: 'ce.analise.alertas.colunas.duracao',
            cellTooltip: false,
            headerCellClass: 'data-testid=alert-vtabela-duracao-header',
            cellTemplate: `
              <div class='ui-grid-cell-contents text-center' ng-if="row.entity.duracao === 0" title="{{ grid.appScope.tooltipNaoAplica }}">
                <div ng-if="row.entity.duracao === 0" data-testid=alert-vtabela-duracao-{{row.entity.placa}}> N/A </div>
              </div>
              <div class='ui-grid-cell-contents text-center' ng-if="row.entity.duracao > 0" title="{{row.entity.duracao | humanizedDuration: true}}">
                <div ng-if="row.entity.duracao > 0" data-testid=alert-vtabela-duracao-{{row.entity.placa}}> {{ row.entity.duracao | humanizedDuration: true }} </div>
              </div>
            `
          },
          {
            name: 'pontoReferencia',
            displayName: 'ce.analise.alertas.colunas.pontoReferencia',
            headerCellClass: 'data-testid=alert-vtabela-pontoReferencia-header',
            cellClass: 'data-testid=alert-vtabela-pontoReferencia-cell',
            enableSorting: false
          },
          {
            name: 'categoria',
            displayName: 'ce.analise.alertas.colunas.categoria',
            headerCellClass: 'data-testid=alert-vtabela-categoria-header',
            cellClass: 'data-testid=alert-vtabela-categoria-cell',
            enableSorting: false
          },
          {
            name: 'cercaEletronica',
            displayName: 'ce.analise.alertas.colunas.cercaEletronica',
            headerCellClass: 'data-testid=alert-vtabela-cercaEletronica-header',
            cellClass: 'data-testid=alert-vtabela-cercaEletronica-cell',
            enableSorting: false
          },
          {
            name: 'logradouro',
            displayName: 'ce.analise.alertas.colunas.logradouro',
            enableSorting: false,
            headerCellClass: 'data-testid=alert-vtabela-logradouro-header',
            cellTemplate: `
            <div class='ui-grid-cell-contents' title="{{ row.entity.logradouro ? row.entity.logradouro : '-'}}" data-testid=alert-vtabela-logradouro-{{row.entity.placa}}>
              <a ng-href='{{grid.appScope.goToHistoricoTimeline(row.entity)}}' target='_blank' ng-if='grid.appScope.exibeInfoLogradouro(row.entity)'>
                {{ row.entity.logradouro ? row.entity.logradouro : '-'}}
              </a>
              <span ng-if='row.entity.logradouro === undefined'> - </span>
            </div>`
          },
          {
            name: 'localizacao',
            displayName: 'ce.analise.alertas.colunas.localizacao',
            enableSorting: false,
            headerCellClass: 'data-testid=alert-vtabela-localizacao-header',
            cellClass: 'data-testid=alert-vtabela-localizacao-cell',
            width: '5%'
          },
          {
            name: 'datahora',
            displayName: 'ce.analise.alertas.colunas.dataHora',
            type: 'date',
            headerCellClass: 'data-testid=alert-vtabela-datahora-header',
            cellTemplate: `
              <div class='ui-grid-cell-contents' title='{{grid.appScope.getDataHora(row.entity)}}'
              data-testid=alert-vtabela-datahora-{{row.entity.placa}}>
                {{grid.appScope.getDataHora(row.entity)}}
              </div>
            `
          },
          {
            name: 'status',
            displayName: 'ce.analise.alertas.colunas.status',
            width: '5%',
            enableSorting: true,
            headerCellClass: 'data-testid=alert-vtabela-status-header',
            cellTemplate: `
            <div class='ui-grid-cell-contents'>
              <span class='badge {{grid.appScope.status[row.entity.status].cor}}'>
                {{ grid.appScope.status[row.entity.status].label }}
              </span>
              <span class='fa-stack fa-1x historicoAlerta-icon-calendar text-primary' ng-if='row.entity.comentarios > 0 && grid.appScope.roleCadastrar'>
                <i class='fa fa-comment fa-stack-1x'></i>
                <span class='fa-stack-1x calendar-text' data-testid=alert-vtabela-status-{{row.entity.placa}}>
                  {{ row.entity.comentarios }}
                </span>
              </span>
            </div>`
          },
          {
            name: 'atribuido',
            displayName: 'ce.analise.alertas.colunas.atribuido',
            enableSorting: false,
            headerCellClass: 'data-testid=alert-vtabela-atribuido-header',
            cellClass: 'data-testid=alert-vtabela-atribuido-cell'
          },
          {
            name: 'usuarioEmailAssistiuVideo',
            displayName: 'ce.analise.alertas.colunas.usuarioEmailAssistiuVideo',
            headerCellClass: 'data-testid=alert-vtabela-usuarioEmailAssistiuVideo-header',
            cellClass: 'data-testid=alert-vtabela-usuarioEmailAssistiuVideo-cell',
            enableSorting: false
          },
          {
            name: 'anexos',
            displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.anexos',
            headerCellClass: 'data-testid=alert-vtabela-anexos-header',
            cellTemplate: `
              <div class="ui-grid-cell-contents" data-testid=alert-vtabela-status-{{row.entity.placa}}>
                <div ng-if='row.entity.anexos != null' class='anexo-div'>
                  <span class="material-icons icon-anexo">
                    attach_file
                  </span>
                  <span>
                  <a
                  uib-tooltip="{{ grid.appScope.cliqueParaVer }}"
                  class="btn-solicitar-video-foto"
                  ng-click='grid.appScope.abrirModalAnexos(row.entity)'
                  id="abrirModalAnexo">
                  {{ 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.possuiAnexos' | translate }}
                  </span>
                </div>
              </div>`
          },
          {
            name: 'feedback',
            displayName: 'ce.analise.alertas.colunas.feedbackVideo',
            enableSorting: false,
            headerCellClass: 'data-testid=alert-vtabela-feedback-header',
            cellTemplate: `
              <div class='ui-grid-cell-contents text-center' data-testid=alert-vtabela-status-{{row.entity.placa}}>
                <i ng-if='!row.entity.isEventoVsafe'>{{ 'v3gwc.vfiltro.constantes.feedbackVideo.naoSeAplica' | translate }}</i>
                <i ng-if='row.entity.isEventoVsafe && row.entity.feedback' class='trimble-icon trimble-thumbs-up' uib-tooltip='{{ row.entity.usuarioFeedbackEmail }}'></i>
                <i ng-if='row.entity.isEventoVsafe && row.entity.feedback === false' class='trimble-icon trimble-thumbs-down' uib-tooltip='{{ row.entity.usuarioFeedbackEmail }}'></i>
              </div>
            `
          },
          {
            name: 'video',
            displayName: 'ce.analise.alertas.colunas.video.colLabel',
            enableSorting: false,
            exporterSuppressExport: true,
            enableColumnResizing: false,
            enableCellEdit: false,
            pinnedRight: true,
            width: 60,
            headerCellTemplate: `
              <div tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" title="">
                <span class="ui-grid-header-cell-label">
                  <i class='fa fa-info-circle' data-testid=alert-vtabela-video-header
                    uib-tooltip=' {{ grid.appScope.videoNaoDisponivel }}'
                    tooltip-placement='top-right'></i>
                </span>
              </div>
            `,
            cellTemplate: `
              <div class='ui-grid-cell-contents text-left'>
                <span ng-if='row.entity.videoId || row.entity.statusVideo || row.entity.snapshotId' class="icone-status-video-foto" data-testid=alert-vtabela-video-play-{{row.entity.placa}}>
                  <event-viewer-link
                    class="historico-alerta-video-play-button"
                    ng-if="grid.appScope.isVideoPlayer(row.entity) || grid.appScope.isPhotoReady(row.entity)"
                    id-video="{{row.entity.videoId}}"
                    snapshot-id="{{row.entity.snapshotId}}"
                    datahora="{{row.entity.datahora}}"
                    latitude="{{row.entity.coordinates.latitude}}"
                    longitude="{{row.entity.coordinates.longitude}}"
                    vehicle-id="{{row.entity.veiculo.id}}"
                    vehicle-prefix="{{row.entity.prefixo}}"
                    vehicle-plate="{{row.entity.veiculo.placa}}"
                    driver-name="{{row.entity.motorista}}"
                    tipo-evento="{{row.entity.tipo}}"
                    threshold="{{row.entity.limiar}}"
                    event-duration="{{row.entity.duracao}}"
                    actual-value="{{row.entity.excesso}}"
                    event-level="{{row.entity.nivel}}"
                    hide-text="true"
                    is-assistido="grid.appScope.isVideoAssistido(row.entity.statusVideo)"
                    show-tooltip="true"
                    ng-click='grid.appScope.onVideoClick()'>
                  </event-viewer-link>
                  <i class='fa fa-clock-o requisicao-pendente'
                    ng-if='grid.appScope.isPendingVideo(row.entity) || grid.appScope.isPendingPhoto(row.entity)'
                    uib-tooltip='{{ grid.appScope.processando }}'>
                  </i>
                </span>
                <span ng-if='grid.appScope.isRequestVideo(row.entity)' class="icone-status-video-foto" data-testid=alert-vtabela-video-solicitar-{{row.entity.placa}}>
                  <a
                    uib-tooltip="{{ grid.appScope.solicitarVideo }}"
                    class="btn-solicitar-video-foto"
                    ng-if='row.entity.isVmov && !grid.appScope.isVideoProcessando(row.entity.statusVideo)'
                    ng-click='grid.appScope.abrirModalRequisicaoVideo(row.entity)'>
                    <i class="fa fa-plus"></i>
                  </a>
                </span>
                <span ng-if='grid.appScope.isRequestSnapshot(row.entity)' class="icone-status-video-foto">
                  <a
                    uib-tooltip="{{ grid.appScope.solicitarImagem }}"
                    class="btn-solicitar-video-foto"
                    ng-click='grid.appScope.openRequestSnapshotPopup(row.entity)'>
                    <i class="fa fa-plus"></i>
                  </a>
                </span>
                <span
                  class="sem-video-icone"
                  ng-if="grid.appScope.isUnavailableVideo(row.entity)">
                  <i class="trimble-no-video" uib-tooltip="{{ grid.appScope.semVideo }}"></i>
                </span>
                <span
                  class="material-symbols-filled no-photo-icon"
                  ng-if="grid.appScope.isUnavailablePhoto(row.entity)"
                  uib-tooltip="{{ grid.appScope.semFoto }}">
                    no_photography
                </span>
              </div>
            `
          }
        ]
      };
    }

    shouldShowSeePhotoDialog(row) {
      return row.identificacao && row.identificacao.podeAbrirVigenciaManual;
    }

    isPendingVideo(alerta) {
      return alerta.videoId && this.roleVisualizarVideo && this.isVideoProcessando(alerta.statusVideo);
    }

    isPendingPhoto(alerta) {
      return !alerta.videoId && this.featureFlagPremiumTelemetry && alerta.snapshotId && this.isSnapshotStatusPending(alerta.statusSnapshot);
    }

    isRequestVideo(alerta) {
      return (!alerta.hasPremiumTelemetry || !this.featureFlagPremiumTelemetry) && !alerta.videoId && !alerta.snapshotId && this.roleSolicitarVideo;
    }

    isRequestSnapshot(alerta) {
      return this.featureFlagPremiumTelemetry && alerta.hasPremiumTelemetry && !alerta.videoId && !alerta.snapshotId;
    }

    isUnavailableVideo(alerta) {
      return (!alerta.hasPremiumTelemetry || !this.featureFlagPremiumTelemetry) && this.roleVisualizarVideo && alerta.isVmov && this.isVideoIndisponivel(alerta.statusVideo);
    }

    isUnavailablePhoto(alerta) {
      return !alerta.videoId && this.featureFlagPremiumTelemetry && alerta.hasPremiumTelemetry && this.isPhotoStatusUnavailable(alerta.statusSnapshot);
    }

    isVideoProcessando(status) {
      return status === 'CADASTRADO' || status === 'BAIXANDO' || status === 'CONVERTENDO' || status === 'PENDENTE';
    }

    isSnapshotStatusPending(status) {
      return status === 'PENDING' || status === 'REQUESTED' || status === 'CONVERTING';
    }

    isVideoPlayer(alerta) {
      return this.isVideoDisponivel(alerta.statusVideo) && (!alerta.snapshotId || !this.featureFlagPremiumTelemetry);
    }

    isVideoDisponivel(status) {
      return status === 'ASSISTIDO' || status === 'CONCLUIDO';
    }

    isPhotoReady(alerta) {
      return alerta.statusSnapshot === 'DONE' && alerta.snapshotId && (!alerta.videoId || !this.featureFlagPremiumTelemetry);
    }

    isVideoIndisponivel(status) {
      return status === 'DESCONHECIDO' || status === 'ERRO' || status === 'INDISPONIVEL';
    }

    isPhotoStatusUnavailable(status) {
      return status === 'ERROR' || status === 'UNAVAILABLE';
    }

    isVideoAssistido(status) {
      return status === 'ASSISTIDO';
    }

    abrirModalRequisicaoVideo(alerta) {
      this.uibModal.open({
        component: 'modalRequisicaoVideo',
        backdrop: 'static',
        resolve: {
          alerta: () => alerta
        }
      }).result.then(() => {
        alerta.statusVideo = 'CADASTRADO';
      });
    }

    openRequestSnapshotPopup(alerta) {
      this.uibModal.open({
        component: 'modalRequisicaoVideo',
        backdrop: 'static',
        resolve: {
          alerta: () => alerta,
          isSnapshotRequest: () => true
        }
      }).result.then((result) => {
        alerta.snapshotId = result.snapshotId;
        alerta.statusSnapshot = 'PENDING';
      });
    }

    onVideoClick() {
      this.googleTagManagerUtils.sendEventClickGTM('analise_alertas_reproduzir_video', this.serviceVfiltro.factoryVfiltro.user);
    }

    setPopoverContent(codigo, content, obd) {
      return this.trusted[codigo] || (this.trusted[codigo] = this.sce.trustAsHtml(
        (angular.isDefined(content) && content !== null ? `${content}<br/>` : '') + this.translate.instant(`ce.analise.alertas.codigoDeFalhaPt1`) +
        (obd ? this.translate.instant(`ce.analise.alertas.codigoDeFalhaPt2`) : '.')));
    }

    consultar() {
      this.callServiceForPageContent();
    }

    getTooltipMsgExcesso(row) {
      let tipo = this.alertasEnum.getAlerta(row.entity.tipo);
      return tipo.id === 'EXCESSO_VELOCIDADE_POR_VIA' || tipo.id === 'EXCESSO_VELOCIDADE' ? this.nivel[row.entity.nivel].label : row.entity.excesso;
    }

    callServiceForPageContent() {
      this.vtabela.pageSize = this.vtabela.pageSize ? this.vtabela.pageSize : 50;
      this.vtabela.vtabelaLocalstorage.pageSize = this.pageSize;
      const offset = (Number(this.vtabela.currentPage || 1) - 1) * this.vtabela.pageSize;

      this.vtabela.setVtabelaUrl();
      this.vtabela.grid.getPage(this.vtabela.pageSize, offset, 'datahora', this.vtabela.sortDirection ? this.vtabela.sortDirection : 'desc');
    }

    convertSpeedMeasurement(limiar) {
      const convertibleUnits = ['Km/h/s', 'Km/h', 'Km'],
          unit = convertibleUnits.find((u) => {
            return limiar.includes(u);
          }),
          value = parseFloat(limiar.replace(unit, '').trim()),
          unitConversionMap = {
            'Km/h': {converter: 'handleKmToMiles', newUnit: 'mph'},
            'Km/h/s': {converter: 'handleKmToMiles', newUnit: 'mph/s'},
            Km: {converter: 'handleKmToMiles', newUnit: 'mi', round: true}
          };

      if (!unit || this.speedMeasurement !== 'MILES') {
        return limiar;
      }

      if (isNaN(value)) {
        return limiar;
      }

      if (unitConversionMap.hasOwnProperty(unit)) {
        const {converter, newUnit, round = false} = unitConversionMap[unit];
        let convertedValue = this.convertersService[converter](value, !round);
        if (round) {
          convertedValue = Math.ceil(convertedValue);
        }
        return `${convertedValue} ${newUnit}`;
      }
      return limiar;
    }

    getItemVtabela(item) {
      return {
        id: item.id,
        uuid: item.uuid,
        prefixo: item.veiculo.prefixo,
        placa: item.veiculo.placa,
        grupo: item.veiculo.grupo ? item.veiculo.grupo.nome : '-',
        motorista: item.motorista ? item.motorista : null,
        motoristaId: item.motoristaId,
        uo: item.uoNome,
        uoId: item.uo,
        tipo: item.tipo,
        limiar: this.convertSpeedMeasurement(item.limiar),
        excesso: this.convertSpeedMeasurement(item.excesso),
        nivel: item.nivel ? item.nivel : '',
        duracao: item.duracao,
        logradouro: item.logradouro,
        localizacao: item.localizacao,
        datahora: item.data,
        veiculo: {
          id: item.veiculo.id,
          placa: item.veiculo.placa
        },
        status: item.status,
        atribuido: item.usuarioAtribuido && item.usuarioAtribuido.login ? item.usuarioAtribuido.login : '',
        usuarioAtribuido: item.usuarioAtribuido,
        comentarios: item.qtdComentarios,
        videoId: item.videoId,
        statusVideo: item.statusVideo,
        miscInfo: item.miscInfo,
        pontoReferencia: item.pontoReferencia && item.pontoReferencia.nome ? item.pontoReferencia.nome : '',
        categoria: item.pontoReferencia && item.categoriaPontoReferencia ? item.categoriaPontoReferencia.nome : '',
        cercaEletronica: item.cercaEletronica && item.cercaEletronica.nome ? item.cercaEletronica.nome : '',
        computadorDeBordo1: item.computadorDeBordo1,
        computadorDeBordo2: item.computadorDeBordo2,
        isVmov: item.isVmov,
        usuarioEmailAssistiuVideo: item.usuarioEmailAssistiuVideo,
        temPermissaoVeiculo: item.temPermissaoVeiculo,
        feedback: item.feedback,
        meio: item.meio,
        usuarioFeedbackEmail: item.usuarioFeedbackEmail,
        isEventoVsafe: item.isEventoVsafe,
        mensagemOVUUID: item.mensagemOVUUID,
        anexos: item.anexos,
        snapshotId: item.snapshotId,
        coordinates: {latitude: item.latitude, longitude: item.longitude},
        identificacao: item.identificacao,
        hasPremiumTelemetry: item.hasPremiumTelemetry,
        statusSnapshot: item.statusSnapshot
      };
    }

    isSortAscent(columnName) {
      return this.tableState.sort.predicate === columnName && !this.tableState.sort.reverse;
    }

    isSortDescent(columnName) {
      return this.tableState.sort.predicate === columnName && this.tableState.sort.reverse;
    }

    deveMostrarBotaoCsv() {
      const inicioFiltro = this.moment(this.getParams().inicio);
      return inicioFiltro.isSame(this.dataPreprocessada) || inicioFiltro.isAfter(this.dataPreprocessada);
    }

    replaceStatusVideos(filtro) {
      if (angular.isDefined(filtro.statusVideos)) {
        let statusVideoLabels = new Set(filtro.statusVideos.split(','));
        if (statusVideoLabels.size === 3) {
          delete filtro.statusVideos;
        } else {
          filtro.statusVideos = [...statusVideoLabels].map(label => this.statusVideo.getByAgrupador(label)).join(',');
          if (statusVideoLabels.has('SEM_VIDEO')) {
            filtro.notStatusVideos = _.flatten(
              this.statusVideo.getAgrupadores()
                .filter(agrupador => !statusVideoLabels.has(agrupador))
                .map(agrupador => this.statusVideo.getByAgrupador(agrupador))
            ).join(',');
          }
        }
      }
      return filtro;
    }

    getParams(limit = 50, offset = 0, orderBy = 'datahora', orderDirection = 'desc') {
      let filtro = this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar, 'uo');
      filtro = this.replaceStatusVideos(filtro);
      return angular.extend(filtro, {
        limit,
        offset,
        orderBy,
        orderDirection
      });
    }

    getUrlCsv() {
      var params = this.getParams();
      params.limit = this.maxCsvSize;
      params.offset = null;
      params.timezone = this.currentTimezone;
      const uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id;
      return `/alerta/relatorio/csv/${uoId}?` + this.httpParamSerializer(params);
    }

    openDetalhesModal(alertas, status) {
      if (status) {
        status = this.translate.instant(status);
      }
      this.uibModal.open({
        templateUrl: 'historico-alerta/detalhes-alerta.html',
        controller: 'DetalhesAlertaCtrl as detalhesAlertaModal',
        resolve: {
          alertas: () => alertas,
          status: () => status,
          descricaoStatus: () => this.status
        }
      }).result.finally(() => this.consultar());
    }

    getUrlHistoricoTimeline(item) {
      const uo = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id,
          vfiltroToHistoricoDetalhado = [
              {
                key: this.keys.uo.name,
                value: {
                  id: uo
                }
              },

              {
                key: this.keys.dateTime.name,
                value: {
                  startDate: this.moment(item.datahora).subtract(1, 'h'),
                  endDate: this.moment(item.datahora).add(1, 'h'),
                  tipo: 'personalizado'
                }
              },
              {
                key: this.keys.veiculo.name,
                value: {
                  id: item.veiculo.id
                }
              }
          ];
      return this.httpParamSerializer({
        vfiltro: this.urlService.getObjectVfiltro(vfiltroToHistoricoDetalhado),
        evento: item.tipo,
        date: item.datahora
      });
    }

    goToHistoricoTimeline(item) {
      return `/historico-timeline?${this.getUrlHistoricoTimeline(item)}`;
    }

    hasLinkPortalPassivelManutencao(row) {
      return row.tipo === 'VEICULO_SEM_SINAL' && !!row.miscInfo.linkPortal;
    }

    setPopoverContentVeiculoSemSinal(row) {
      const link = `<a href="${row.miscInfo.linkPortal}" target="_blank">${this.translate.instant('ce.analise.alertas.portal')}</a>`;
      let stringAlerta = `${this.translate.instant('ce.analise.alertas.veiculoSemSinalPt1')} ${link}`;
      return this.tooltipSemSinal[stringAlerta] || (this.tooltipSemSinal[stringAlerta] = this.sce.trustAsHtml(stringAlerta));
    }

    exibeInfoLogradouro(row) {
      return row.tipo !== 'VEICULO_SEM_SINAL';
    }

    getDataHora(row) {
      if (row.tipo === 'VEICULO_SEM_SINAL') {
        return '-';
      }
      return this.filter('date')(row.datahora, this.dateTimeFormat);
    }

    getDescricao(tipoAlerta) {
      const alerta = this.alertasEnum.getAlerta(tipoAlerta);
      return angular.isDefined(alerta) ? this.translate.instant(alerta.descricao) : '-';
    }

    getEventTooltip(tipoAlerta) {
      const alerta = this.alertasEnum.getAlerta(tipoAlerta);
      return angular.isDefined(alerta) && angular.isDefined(alerta.tooltip) ? this.translate.instant(alerta.tooltip) : null;
    }

    isEventTooltip(tipoAlerta) {
      return !!this.alertasEnum.getAlerta(tipoAlerta).tooltip;
    }

    abrirModalAnexos(row) {
      /* eslint-disable */
      const botaoVerAnexos = document.querySelector('#abrirModalAnexo'),
      modal = document.createElement('vfwc-external-abrir-dialog-anexos'); /* eslint-enable */
      modal.anexos = row.anexos;
      modal.relatorioAlertaItem = {
        uoId: this.uoIdUsuarioLogado ? this.uoIdUsuarioLogado : row.uoId,
        uoNome: row.uo,
        motorista: row.motorista,
        tipo: {
          id: row.tipo,
          descricao: this.getDescricao(row.tipo)
        }
      };
      modal.isAlertas = true;
      botaoVerAnexos.appendChild(modal);
    }

    abrirModalAbonarEvento(alerta) {
      /* eslint-disable */
      const botaoImportar = document.querySelector('#abrirModalAbono'),
      abrirModal = document.createElement('vfwc-abrir-dialog-abono'); /* eslint-enable */
      abrirModal.isFromAlertAnalysis = true;
      abrirModal.uo = {
        nome: alerta.uo,
        uoId: alerta.uoId
      };
      abrirModal.alerta = {
        tipo: {
          id: alerta.tipo,
          descricao: this.getDescricao(alerta.tipo)
        },
        duracao: alerta.duracao ? alerta.duracao : null,
        datahora: alerta.datahora,
        veiculo: alerta.veiculo,
        uo: alerta.uoId,
        mensagemOVUUID: alerta.mensagemOVUUID
      };
      botaoImportar.appendChild(abrirModal);
    }

    verificaSeTipoExpurgo(tipoAlerta) {
      return this.alertasExpurgo.some(alerta => alerta.id === tipoAlerta);
    }

    verificaSeGTP(meio) {
      return meio === 'GTP';
    }

    getMotoristaCellTemplate() {
      return `
        <div class='ui-grid-cell-contents'>
          <div
            id="see-photo-dialog-no-identification"
            ng-if="!row.entity.motorista && grid.appScope.shouldShowSeePhotoDialog(row.entity)">
            <snapshot-link
              id-snapshot="{{row.entity.identificacao.snapshotId}}"
              date-ignition-on="{{row.entity.identificacao.datahora}}"
              date-ignition-off="{{row.entity.identificacao.datetimeIgnitionOff}}"
              pode-abrir-vigencia-manual="row.entity.identificacao.podeAbrirVigenciaManual"
              id-veiculo="{{row.entity.identificacao.veiculoId}}"
              uo-veiculo="{{row.entity.uoId}}"
              s3key="{{row.entity.identificacao.s3Key}}"
              prefix-container="alert-report-container"
              text-button="ce.analise.alertas.colunas.motorista.semIdentificacao"
              alert-id="{{row.entity.id}}"
              on-close-dialog="grid.appScope.consultar()">
            </snapshot-link>
          </div>
          <div
            id="with-driver"
            ng-if="!grid.appScope.shouldShowSeePhotoDialog(row.entity)">
            <motorista-link
              motorista="row.entity.motorista"
              motorista-id="row.entity.motoristaId"
              index="$index">
            </motorista-link>
          </div>
        </div>
      `;
    }
}

  angular
    .module('historicoAlerta')
    .controller('HistoricoAlertaCtrl', HistoricoAlertaCtrl);
}());
