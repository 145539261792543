(function () {
  'use strict';

  /* global _, L, Intl, Blob */
  class BdmDetalhesCtrl {
    constructor($window, $stateParams, Bdm, MapasUtil, $filter, $scope, $compile, leafletData, Eventos,
      VFlash, $location, SpiderfierService, MarkerService, moment, Onboarding, MockBDMDetalhes, IDBDMDetalhes,
      ServiceVfiltro, FactoryVfiltro, $translate, HttpServiceV3g, $rootScope, MeasurementUnitConverterService) {
      this.translate = $translate;
      this.window = $window;
      this.stateParams = $stateParams;
      this.bdmService = Bdm;
      this.mapasUtilService = MapasUtil;
      this.spiderfierService = SpiderfierService;
      this.markerService = MarkerService;
      this.location = $location;
      this.onboarding = Onboarding;
      this.mockBDMDetalhes = MockBDMDetalhes;
      this.idConfluence = IDBDMDetalhes;
      this.httpService = HttpServiceV3g;
      this.filter = $filter;
      this.scope = $scope;
      this.compile = $compile;
      this.moment = moment;
      this.todasVigencias = false;
      this.motorista = null;
      this.inicio = null;
      this.duracaoTotal = 0;
      this.duracao = null;
      this.distancia = 0;
      this.distanciaRelativaConsumo = 0;
      this.distanciaRelativaConsumoEletrico = 0;
      this.veiculos = [];
      this.detalhes = [];
      this.vigenciaSelecionada = null;
      this.leafletData = leafletData;
      this.eventos = Eventos;
      this.translate = $translate;
      this.flash = VFlash;
      this.logradouroInicio = null;
      this.logradouroFim = null;

      this.tiles = MapasUtil.tiles.veltec;
      this.defaults = MapasUtil.defaults;
      this.geoJson = {};
      this.mapCenter = MapasUtil.getCenter();
      this.markersGroup = new L.FeatureGroup();
      this.pathGroup = new L.FeatureGroup();
      this.eventoSelecionado = '';

      this.onClickTipoEvento = this.filtraEvento();
      this.onClickPosicao = this.destacarMarcador();
      this.markersPorPosicao = new Map();
      this.currentTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.eventoDescida = this.translate.instant(this.eventos.DESCIDA.tipo);
      this.eventoDescidaFaixa1 = this.translate.instant(this.eventos.DESCIDA_FAIXA1.tipo);
      this.eventoDescidaFaixa2 = this.translate.instant(this.eventos.DESCIDA_FAIXA2.tipo);
      this.eventoDescidaFaixa3 = this.translate.instant(this.eventos.DESCIDA_FAIXA3.tipo);
      this.faixasDescida = {
        segundosFaixa1: 5,
        segundosFaixa2: 30
      };

      this.rootScope = $rootScope;
      this.measurementUnitConverterService = MeasurementUnitConverterService;

      this.dateTimeFormat = this.measurementUnitConverterService.getDateFormat(this.rootScope.usuario.measurementUnits.dateTimeFormat);
      this.distanceUnit = this.measurementUnitConverterService.getDistanceUnit(this.rootScope.usuario.measurementUnits.distanceMeasurement);
      this.fuelUnit = this.measurementUnitConverterService.getFuelUnit(this.rootScope.usuario.measurementUnits.fuelMeasurement);
      this.fuelEfficiency = this.measurementUnitConverterService.getFuelEfficiencyUnit(this.rootScope.usuario.measurementUnits.fuelMeasurement);
      this.energyEfficiency = this.measurementUnitConverterService.getEnergyEfficiencyUnit(this.rootScope.usuario.measurementUnits.fuelMeasurement);

      this.setMapEvents();
      ServiceVfiltro.init(FactoryVfiltro.get([]))
      .then(() => {
        let filtroLocalstorage = angular.fromJson(this.window.localStorage.getItem(`vfiltro-${ServiceVfiltro.factoryVfiltro.user.id}`));
        filtroLocalstorage.dateTime = [
          this.moment(this.stateParams.dia, 'YYYY-MM-DD').startOf('day').format('YYYY.MM.DD.HH.mm.ss.ZZ'),
          this.moment(this.stateParams.dia, 'YYYY-MM-DD').endOf('day').format('YYYY.MM.DD.HH.mm.ss.ZZ')
        ];
        filtroLocalstorage.motorista = this.stateParams.motoristaId;
        this.window.localStorage.setItem(`vfiltro-${ServiceVfiltro.factoryVfiltro.user.id}`, angular.toJson(filtroLocalstorage));
        this.loadServerData();
      })
      .catch(() => {
        this.leafletData.unresolveMap('bdm-map');
      });
    }

    filtraEvento() {
      return (index) => {
        this.eventoSelecionado = index;
        if (this.detalhes.length === 0) {
          this.flash.filterWarn();
        } else if (this.todasVigencias) {
          this.selecionarTodasVigencias();
        } else {
          this.limparVariaveisLayers();
          this.popularDadosDaVigencia(this.vigenciaSelecionada);
          this.inserirVigenciaNoMapa(this.vigenciaSelecionada);
        }
      };
    }

    destacarMarcador() {
      return (posicao, centralizarFim) =>
        this.leafletData.getMap('bdm-map')
          .then(map => {
            if (posicao.miscInfo && posicao.miscInfo.diferencaAltitude !== -1) {
              this.diferencaAltitude = posicao.miscInfo.diferencaAltitude;
            }
            const marker = this.markersPorPosicao.get(posicao);
            if (centralizarFim && angular.isDefined(posicao.fim)) {
              const markerFim = this.markersPorPosicao.get(posicao.fim);
              map.fitBounds([marker.getLatLng(), markerFim.getLatLng()]);
            } else {
              map.panTo(marker.getLatLng());
            }
            marker.openPopup(marker.getLatLng());
          });
    }

    loadServerData() {
      this.isDemo = this.onboarding.startOnboarding(this.idConfluence);

      if (angular.isUndefined(this.stateParams.dia) ||
        angular.isUndefined(this.stateParams.motoristaId)) {
        return;
      }

      if (this.isDemo) {
        let retorno = angular.copy(this.mockBDMDetalhes);
        retorno = retorno.map(x => {
          if (this.stateParams.motoristaId === 1) {
            x.motorista = 'André Castanheira';
          } else if (this.stateParams.motoristaId === 2) {
            x.motorista = 'Gil Larangeira';
          } else if (this.stateParams.motoristaId === 3) {
            x.motorista = 'Iberê Lages';
          } else {
            x.motorista = 'Silvério Mansilla';
          }

          x.veiculo = this.location.search().veiculo;

          return x;
        });
        this.setInfo(retorno);
      } else {
        this.bdmService.getDetalhes(this.stateParams.motoristaId, this.stateParams.dia).then(data => this.setInfo(data));
      }
    }

    setInfo(data) {
      this.detalhes = data.map(v => {
        // Icone parado 1 esta sobrecarregando. Removido até proximo sprint.
        v.posicoes.forEach(p => {
          if (p.evento === this.translate.instant(this.eventos.PARADO.tipo) && p.duracao <= 300) {
            p.evento = this.translate.instant('ce.enum.eventos.movimento');
          }
          if (p.evento === this.translate.instant('ce.enum.eventos.pontoDeReferencia')) {
            p.evento = this.translate.instant('ce.enum.eventos.movimento');
          }
        });
        this.faixasDescida = v.faixasDescida;
        v.posicoes = this.mapasUtilService.ajustarEventos(v.posicoes, v.faixasDescida);
        this.mapasUtilService.marcarPosicoesInvalidasNoInicio(v.posicoes);

        if (v.fim) {
          this.duracaoTotal += this.moment(v.fim, 'YYYY/MM/DD HH:mm:ssZ').diff(this.moment(v.inicio, 'YYYY/MM/DD HH:mm:ssZ'), 'seconds');
        }

        return {vigencia: v};
      });

      if (this.detalhes.length === 0) {
        this.flash.filterWarn();
      } else {
        this.inicio = this.detalhes[0].vigencia.inicio;
        this.selecionarTodasVigencias();
      }
    }

    selecionarTodasVigencias() {
      this.limparVariaveisLayers();
      if (!this.todasVigencias) {
        this.resumoPeriodo = this.detalhes.map(detalhe => detalhe.vigencia);
      }

      this.mediaConsumo = null;
      this.consumoCombustivel = 0;
      this.mediaConsumoEletrico = null;
      this.consumoEletrico = 0;
      this.detalhes.forEach((element) => {
        this.motorista = element.vigencia.motorista;
        this.distancia += element.vigencia.distanciaPercorrida;
        this.veiculos.push(element.vigencia.veiculo);
        let posicoes = _.sortBy(this.mapasUtilService.flatPosicoes(element.vigencia.posicoes), 'data'),
            posicoesValidas = posicoes.filter(p => p.valida),
            consumoVigencia = (element.vigencia.consumoCombustivelParado || 0) +
              (element.vigencia.consumoCombustivelMovimento || 0) +
              (element.vigencia.consumoCombustivelGpsInvalido || 0),
            consumoVigenciaEletrico = (element.vigencia.consumoEletricoParado || 0) +
              (element.vigencia.consumoEletricoMovimento || 0) +
              (element.vigencia.consumoEletricoGpsInvalido || 0);

        this.consumoCombustivel += consumoVigencia > 0 ? consumoVigencia : 0;
        this.consumoEletrico += consumoVigenciaEletrico > 0 ? consumoVigenciaEletrico : 0;

        if (consumoVigencia > 0) {
          this.distanciaRelativaConsumo += element.vigencia.distanciaPercorrida;
        }

        if (consumoVigenciaEletrico > 0) {
          this.distanciaRelativaConsumoEletrico += element.vigencia.distanciaPercorrida;
        }

        this.setPath(posicoesValidas);
        this.setPathEventos(posicoesValidas, this.eventoSelecionado);
      });

      if (this.consumoCombustivel > 0) {
        this.mediaConsumo = this.distanciaRelativaConsumo / this.consumoCombustivel;
      }

      if (this.consumoEletrico > 0) {
        this.mediaConsumoEletrico = this.distanciaRelativaConsumoEletrico / this.consumoEletrico;
      }

      this.todasVigencias = true;
      this.logradouroInicio = '';
      this.logradouroFim = '';
      this.duracao = this.duracaoTotal;

      this.insertMarkers(this.detalhes.map(d => this.mapasUtilService.posicoesFiltradas(d.vigencia.posicoes)));
    }

    limparVariaveisLayers() {
      this.pathGroup.clearLayers();
      this.markersGroup.clearLayers();
      this.duracao = null;
      this.veiculos = [];
      this.distancia = 0;
      this.distanciaRelativaConsumo = 0;
      this.distanciaRelativaConsumoEletrico = 0;
      this.diferencaAltitude = null;
      this.markersPorPosicao.clear();
    }

    selecionarVigencia(vigencia) {
      this.limparVariaveisLayers();
      this.resumoPeriodo = [vigencia];
      this.popularDadosDaVigencia(vigencia);
      this.inserirVigenciaNoMapa(vigencia);
    }

    popularDadosDaVigencia(vigencia) {
      this.motorista = vigencia.motorista;
      this.vigenciaSelecionada = vigencia;
      this.logradouroInicio = this.vigenciaSelecionada.localSaida;
      this.logradouroFim = this.vigenciaSelecionada.localChegada;
      this.todasVigencias = false;
      this.veiculos.push(vigencia.veiculo);
      this.distancia += vigencia.distanciaPercorrida;
      this.duracao = vigencia.fim ?
        this.moment(vigencia.fim, 'YYYY/MM/DD HH:mm:ssZ')
          .diff(this.moment(vigencia.inicio, 'YYYY/MM/DD HH:mm:ssZ'), 'seconds') : null;
      this.consumoCombustivel = vigencia.consumoCombustivelParado + vigencia.consumoCombustivelMovimento +
        vigencia.consumoCombustivelGpsInvalido;
      this.mediaConsumo = vigencia.mediaConsumo;
      this.consumoEletrico = vigencia.consumoEletricoParado + vigencia.consumoEletricoMovimento +
      vigencia.consumoEletricoGpsInvalido;
      this.mediaConsumoEletrico = vigencia.mediaConsumoEletrico;
    }

    inserirVigenciaNoMapa(vigencia) {
      let posicoes = _.sortBy(this.mapasUtilService.flatPosicoes(vigencia.posicoes), 'data');
      this.setPath(posicoes);
      this.setPathEventos(posicoes, this.eventoSelecionado);
      this.insertMarkers([this.mapasUtilService.posicoesFiltradas(vigencia.posicoes)]);
    }

    setMapEvents() {
      angular.extend(this.scope, {
        events: {
          map: {
            enable: ['click'],
            logic: 'emit'
          },
          markers: {
            enable: ['click'],
            logic: 'emit'
          }
        }
      });
    }

    onMapControlInit() {
      this.leafletData.getMap('bdm-map').then(map => {
        this.scope.$broadcast('initPontoReferencia', map);
      });
    }

    insertMarkers(trechos) {
      this.leafletData.getMap('bdm-map').then(
        map => {
          let oms = this.spiderfierService.createSpiderfier(map),
              posicoes = this.addPosicaoDescidaFimDescidas(trechos);

          this.markersGroup.clearLayers();

          this.markersPorPosicao = this.markerService
            .buildMarkersAssociadosAsPosicoes(posicoes, this.eventoSelecionado, 'bdm-');

          this.markersPorPosicao.forEach(marker => {
            marker.setPopupContent(this.compile('<div>' + marker.getPopup().getContent() + '</div>')(this.scope)[0]);
            this.markersGroup.addLayer(marker);
            if (!this.eventos.isLocalizacao(marker.options.evento)) {
              oms.addMarker(marker);
            }
          });

          if (!map.hasLayer(this.markersGroup)) {
            map.addLayer(this.markersGroup);
          }
          if (this.markersGroup.getLayers().length > 0) {
            map.fitBounds(this.markersGroup.getBounds(), {padding: [100, 100]});
          }
        }
      );
    }

    addPosicaoDescidaFimDescidas(trechos) {
      return _.flatten(trechos).reduce(
        (acc, posicao) => {
          acc.push(posicao);
          if (posicao.evento && posicao.evento.includes(this.eventoDescida) && angular.isDefined(posicao.fim)) {
            switch (posicao.evento) {
              case this.eventoDescidaFaixa1:
                posicao.fim.evento = this.translate.instant(this.eventos.DESCIDA_FAIXA1_FIM.tipo);
                break;
              case this.eventoDescidaFaixa2:
                posicao.fim.evento = this.translate.instant(this.eventos.DESCIDA_FAIXA2_FIM.tipo);
                break;
              default:
                posicao.fim.evento = this.translate.instant(this.eventos.DESCIDA_FAIXA3_FIM.tipo);
                break;
            }
            posicao.fim.temCoordenada = true;
            posicao.fim.descricao = this.translate.instant(this.eventos.DESCIDA_FAIXA1_FIM.descricao);
            acc.push(posicao.fim);
          }
          return acc;
        }, []);
    }

    setPath(posicoes) {
      this.leafletData.getMap('bdm-map').then(
        (map) => {
          let routeLines = L.polyline(posicoes.map(p => [p.latitude, p.longitude]), {
            color: '#3388FF',
            opacity: 0.75,
            weight: 3
          });
          L.polylineDecorator(routeLines, {
            patterns: [{
              offset: 0,
              repeat: 300,
              symbol: L.Symbol.arrowHead({
                pixelSize: 12,
                pathOptions: {fillOpacity: 0.75, color: '#0033FF', weight: 0}
              })
            }]
          }).addTo(this.pathGroup);
          this.pathGroup.addLayer(routeLines);
          if (!map.hasLayer(this.pathGroup)) {
            map.addLayer(this.pathGroup);
          }
        }
      );
    }

    setPathEventos(posicoes, e) {
      this.leafletData.getMap('bdm-map').then(
        (map) => {
          posicoes.filter(p => this.isEvento(p, e)).map(p=> {
            let coord = [[p.latitude, p.longitude]],
                routeEventsLines = null;
            if (angular.isDefined(p.fim)) {
              coord = coord.concat(this.getPosicoesDentroDoPeriodoDoEvento(p, posicoes));
              coord.push([p.fim.latitude, p.fim.longitude]);
            }
            routeEventsLines = L.polyline(coord, {
              color: 'red',
              weight: 3
            });
            this.pathGroup.addLayer(routeEventsLines);
          });
          if (!map.hasLayer(this.pathGroup)) {
            map.addLayer(this.pathGroup);
          }
        }
      );
    }

    getPosicoesDentroDoPeriodoDoEvento(posicao, posicoes) {
      return posicoes
        .filter(p => this.isDentroDoPeriodo(p, posicao))
        .map(p=> [p.latitude, p.longitude]);
    }

    isDentroDoPeriodo(posicao, posicaoPeriodo) {
      return new Date(posicaoPeriodo.data) < new Date(posicao.data) &&
        new Date(posicaoPeriodo.fim.data) > new Date(posicao.data);
    }

    isEvento(p, e) {
      if (e === this.translate.instant(this.eventos.EXCESSO_VELOCIDADE.tipo)) {
        return this.eventos.isExcessoVelocidade(p.evento);
      } else if (e === this.translate.instant(this.eventos.CURVA_BRUSCA.tipo)) {
        return this.eventos.isCurvaBrusca(p.evento);
      }
      return e === '' || p.evento === e;
    }

    getLinkPdf() {
      return `/boletim/bdm/detalhes/pdf?motoristaId=${this.stateParams.motoristaId}&dia=${this.stateParams.dia}&timezone=${this.currentTimezone}`;
    }

    downloadPdf() {
      this.httpService.get(this.getLinkPdf(), {responseType: 'blob'})
        .then(result => {
          const contentDispositionHeader = result.headers('content-disposition'),
              fileName = contentDispositionHeader ? contentDispositionHeader.split('filename=')[1] : undefined,
              anchor = angular.element('<a/>'),
              file = new Blob([result.data], {type: 'application/pdf'}),
              pdfBrowserUrl = this.window.URL.createObjectURL(file);
          anchor.attr({
            href: pdfBrowserUrl,
            target: '_blank',
            download: fileName ? fileName : 'export.pdf'
          })[0].click();
          this.window.URL.revokeObjectURL(pdfBrowserUrl);
        });
    }

    onLayerSwitch(layerWrapper) {
      this.tiles = layerWrapper.getLayerConfig();
    }
  }

  /**
   * @ngdoc object
   * @name relatorios.bdm.controller:BdmDetalhesCtrl
   *
   * @description
   *
   */
  angular
    .module('relatorios.bdm')
    .controller('BdmDetalhesCtrl', BdmDetalhesCtrl);
}());
