(function () {
  /* global Intl */
  'use strict';
  const FIELDS_OCORR = [
        'id',
        'uoId',
        'dataHora',
        'status',
        'alertasGerados',
        'veiculoId',
        'veiculo.id',
        'veiculo.placa',
        'veiculo.prefixo',
        'veiculo.grupo.id',
        'veiculo.grupo.nome',
        'veiculo.uo.nome',
        'veiculo.uo.id',
        'motorista.id',
        'motorista.nome',
        'motorista.uo.id',
        'motorista.uoId',
        'motorista.uo.nome',
        'motorista.departamentoId',
        'motorista.departamento.id',
        'motorista.departamento.nome',
        'usuarioIdAtribuido',
        'usuario.id',
        'usuario.login'
      ],
      FIELDS_HIST = [
        'statusAnterior',
        'statusNovo',
        'comentario',
        'dataHora',
        'id',
        'usuarioIdAtribuidoNovo',
        'usuarioIdAtribuidoAnterior',
        'usuario.id',
        'usuario.login'
      ];

  class Ocorrencias {
    constructor(HttpServiceV3g, $q, $httpParamSerializer, moment, OcorrenciasEnum, FormatVfiltro) {
      this.q = $q;
      this.httpService = HttpServiceV3g;
      this.httpParamSerializer = $httpParamSerializer;
      this.moment = moment;
      this.formatVfiltro = FormatVfiltro;
      this.ocorrenciasEnum = OcorrenciasEnum;
    }

    getOcorrenciasNova(vfiltro, fields = FIELDS_OCORR) {
      const params = this.httpParamSerializer({
        fields: fields.join(),
        inicio: this.moment().subtract(12, 'hours').format(),
        fim: this.moment().format(),
        status: this.ocorrenciasEnum.NOVA.id,
        uoId: vfiltro.uoId
      });

      return this.httpService.get(`/alerta/ocorrencias?${params}`);
    }

    getOcorrenciasNovaLocFrota(vfiltro, fields = FIELDS_OCORR) {
      const params = this.httpParamSerializer({
        fields: fields.join(),
        uoId: vfiltro.uoId
      });

      return this.httpService.get(`/alerta/ocorrencias/novas/veiculos-permitidos?${params}`);
    }

    getOcorrenciasTratativa(vfiltro) {
      const params = this.httpParamSerializer({
        fields: FIELDS_OCORR.join(),
        status: this.ocorrenciasEnum.EM_TRATATIVA.id,
        uoId: vfiltro.uoId
      });

      return this.httpService.get(`/alerta/ocorrencias?${params}`);
    }

    getOcorrenciasResolvida(vfiltro) {
      const params = this.httpParamSerializer({
        fields: FIELDS_OCORR.join(),
        inicioFinalizacao: this.moment().startOf('day').format(),
        fimFinalizacao: this.moment().format(),
        status: this.ocorrenciasEnum.RESOLVIDA.id,
        uoId: vfiltro.uoId
      });

      return this.httpService.get(`/alerta/ocorrencias?${params}`);
    }

    getOcorrenciaById(idOcorrencia, dataHora) {
      const params = this.httpParamSerializer({
        fields: FIELDS_OCORR.join(),
        inicio: dataHora,
        fim: dataHora,
        id: idOcorrencia
      });

      return this.httpService.get(`/alerta/ocorrencias?${params}`);
    }

    getQuadroOcorrencias(vfiltro) {
      const currentTimezone = this.getTimezone(),
          params = this.httpParamSerializer({
            fields: FIELDS_OCORR.join(),
            timezone: currentTimezone,
            uoId: vfiltro.uoId
          });

      return this.httpService.get(`/alerta/ocorrencias/quadro?${params}`);
    }

    getTimezone() {
      return new Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    getParamsHistDetalhado(ocorrencia) {
      let iniciosAlertas = [], finaisAlertas = [], dataInicio, dataFinal;

      ocorrencia.alertasGerados.forEach(alerta => {
        iniciosAlertas.push(new Date(alerta.dataHoraPrimeiroAlerta).getTime());
        finaisAlertas.push(new Date(alerta.dataHoraUltimoAlerta).getTime());
      });

      dataInicio = this.moment(Math.min(...iniciosAlertas))
        .subtract(1, 'hour')
        .format(this.formatVfiltro);

      dataFinal = this.moment(Math.max(...finaisAlertas))
        .add(1, 'hour')
        .format(this.formatVfiltro);

      return `vfiltro=dateTime:${dataInicio},${dataFinal};veiculo:${ocorrencia.veiculo.id}`;
    }

    getUsers(veiculoIdOcorrencia) {
      return this.httpService.get(`/alerta/usuarios/atribuiveis?veiculoIds=${veiculoIdOcorrencia}`);
    }

    getHistorico(ocorr, timezone) {
      return this.httpService.get(`/alerta/ocorrencias/${ocorr.id}/historicos?dataHora=${ocorr.dataHora}&timezone=${timezone}&fields=${FIELDS_HIST.join()}`);
    }

    getConfigsByUo(uoId) {
      return this.httpService.get(`/configuracao/configuracoes/${uoId}/ocorrencia`);
    }

    getEventPriorityConfigsByUo(uoId) {
      const FIELDS = ['uoId', 'tipo', 'presentationPriority'];
      return this.httpService.get(`/configuracao/configuracoes/${uoId}/ocorrencia/with-fields?fields=${FIELDS}`);
    }
  }

  angular
    .module('quadroOcorrencias')
    .service('Ocorrencias', Ocorrencias);
}());
