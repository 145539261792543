(function () {
  'use strict';

  class AnaliseOpCtrl {
    constructor(AnaliseOperacional, $httpParamSerializer, Uos, Veiculos, Motoristas, $q,
                moment, VFlash, SidebarToggleService, v3gTableLimitService,
                ServiceVfiltro, FactoryVfiltro, Keys, VtabelaFactory, uiGridConstants,
                UrlConfigService, $translate) {
      this.httpParamSerializer = $httpParamSerializer;
      this.q = $q;
      this.uoService = Uos;
      this.moment = moment;
      this.sidebarToggle = SidebarToggleService;
      this.veiculoService = Veiculos;
      this.motoristaService = Motoristas;
      this.analiseOperacional = AnaliseOperacional;
      this.v3gTableLimitService = v3gTableLimitService;
      this.flash = VFlash;
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.urlConfigService = UrlConfigService;
      this.translate = $translate;
      this.isConsumoEletrico = false;
      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          time: true,
          maxDate: this.moment().endOf('day').format(),
          dateLimit: 1,
          defaultValues: {
            hoje: {
              active: true
            },
            ontem: {
              active: true
            }
          }
        },
        {
          name: 'uoId',
          key: this.keys.uo.name,
          size: 6
        },
        {
          name: 'uoParceiraId',
          key: this.keys.uoParceira.name,
          size: 6
        },
        {
          name: 'veiculoId',
          key: this.keys.veiculo.name,
          ignoreValidation: true,
          size: 6,
          required: true
        },
        {
          name: 'motoristaId',
          key: this.keys.motoristas.name,
          ignoreValidation: true,
          size: 6
        }
      ]))
      .then((filtro) => {
        this.user = this.serviceVfiltro.factoryVfiltro.user;
        this.roleVisualizarMapa = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('LOCALIZACAO_ATUAL_VISUALIZAR');
        this.roleVisualizarPonto = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('PONTO_REFERENCIA_VISUALIZAR');
        this.consultar(filtro);
      })
      .catch(() => {
        this.serviceVfiltro.openFiltroDetalhado = true;
      });
    }

    consultar(filtro) {
      this.analiseOperacional.one().get(this.serviceVfiltro.getFiltroBackendNovo(filtro))
        .then(data => {
          this.isConsumoEletrico = data.list.some(item => item.consumoEletrico > 0);
          this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.user, 'analiseOp', 'v1');
          this.setInfoTable(data);
        })
        .catch(() => this.vtabela.setError());
    }

    initVtabela() {
      let objVtabela = {
        exporterPdfFilename: 'Ranking Direção Segura PDF.pdf',
        exporterCsvFilename: 'Ranking Direção Segura CSV.csv',
        exporterExcelFilename: 'Ranking Direção Segura XLSX.xlsx',
        appScopeProvider: this,
        enableRowSelection: false,
        enableRowHeaderSelection: false,
        multiSelect: false,
        enableSelectAll: false,
        showColumnFooter: true,
        isRowSelectable: () => {
          return false;
        },
        columnDefs: [
            {
              name: 'nomeVeiculo',
              displayName: 'ce.analise.movimentoVeiculo.colunas.veiculo',
              pinnedLeft: true,
              type: 'string',
              filterCellFiltered: true,
              enableHiding: false,
              width: 150,
              aggregationTotalTitle: 'Total'
            },
            {
              name: 'statusRelatorio',
              displayName: 'ce.analise.movimentoVeiculo.colunas.ocorrencia',
              type: 'string',
              width: 150,
              filterCellFiltered: true,
              enableHiding: false,
              cellTemplate: `
                <div class='ui-grid-cell-contents text-center centered-label-analise-mov'
                title="{{ row.entity.statusRelatorio }}">
                  <span class='label {{row.entity.statusRelatorioLabel}}'>
                    {{ row.entity.statusRelatorio }}
                  </span>
                </div>
              `
            },
            {
              name: 'inicio',
              displayName: 'ce.analise.movimentoVeiculo.colunas.dataHoraInicial',
              filterCellFiltered: true,
              cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'',
              cellClass: 'text-center',
              width: 130
            },
            {
              name: 'fim',
              displayName: 'ce.analise.movimentoVeiculo.colunas.dataHoraFinal',
              filterCellFiltered: true,
              cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'',
              cellClass: 'text-center',
              width: 130
            },
            {
              name: 'duracao',
              displayName: 'ce.analise.movimentoVeiculo.colunas.duracao',
              cellClass: 'text-center',
              cellFilter: 'humanizedDuration',
              enableFiltering: false,
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'motoristaNome',
              displayName: 'ce.analise.movimentoVeiculo.colunas.motorista',
              type: 'string',
              filterCellFiltered: true,
              cellTemplate: `
                  <div class='ui-grid-cell-contents'>
                    <motorista-link
                      motorista="row.entity.motoristaNome"
                      motorista-id="row.entity.motoristaId"
                      index='$index'>
                    </motorista-link>
                  </div>
                `
            },
            {
              name: 'distanciaPercorrida',
              displayName: 'ce.analise.movimentoVeiculo.colunas.distanciaPercorrida',
              cellFilter: 'decimalWithLabel:\'Km\'',
              cellClass: 'text-center',
              enableFiltering: false,
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'consumo',
              displayName: 'ce.analise.movimentoVeiculo.colunas.consumo',
              type: 'number',
              cellFilter: this.getConsumoCellFilter(),
              cellClass: 'text-center',
              enableFiltering: false,
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'logradouro',
              displayName: 'ce.analise.movimentoVeiculo.colunas.logradouro',
              type: 'string',
              filterCellFiltered: true},
            {
              name: 'pontoReferencia',
              displayName: 'ce.analise.movimentoVeiculo.colunas.pontoReferencia',
              type: 'string',
              cellTemplate: `
              <div class='ui-grid-cell-contents' title="{{ row.entity.pontoReferencia }}">
                <a ng-href='{{ row.entity.linkLocalizacaoFrota }}'
                   target='_blank'
                   ng-if='grid.appScope.roleVisualizarMapa && grid.appScope.roleVisualizarPonto'>
                  {{ row.entity.pontoReferencia }}
                </a>
                <span ng-if='!grid.appScope.roleVisualizarMapa || !grid.appScope.roleVisualizarPonto'>
                  {{ row.entity.pontoReferencia }}
                </span>
              </div>
              `
            },
            {
              name: 'cidade',
              displayName: 'ce.analise.movimentoVeiculo.colunas.cidade',
              type: 'string',
              cellClass: 'text-center',
              filterCellFiltered: true
            },
            {
              name: 'uf',
              displayName: 'ce.analise.movimentoVeiculo.colunas.uf',
              width: 75,
              type: 'string',
              filterCellFiltered: true
            }
        ]
      };
      return objVtabela;
    }

    getItemVtabela(item) {
      return {
        distanciaPercorrida: item.distanciaPercorrida,
        motoristaNome: item.motoristaNome,
        motoristaId: item.motoristaId,
        logradouro: item.logradouro,
        cidade: item.cidade,
        uf: item.uf,
        duracao: item.duracao,
        inicio: item.inicio,
        fim: item.fim,
        nomeVeiculo: item.nomeVeiculo,
        consumo: item.consumo ? item.consumo : item.consumoEletrico,
        statusRelatorio: this.statusText(item.statusRelatorio),
        statusRelatorioLabel: this.statusLabel(item.statusRelatorio),
        pontoReferencia: item.pontoReferencia && item.pontoReferencia.nome,
        linkLocalizacaoFrota: item.pontoReferencia &&
          `/mapas-grid?ponto=${item.pontoReferencia.id}&forceRenderLayer=true`
      };
    }

    setInfoTable(data) {
      this.vtabela.setData(data.list.map(i => {
        return this.getItemVtabela(i);
      }));
    }

    getUrlCsv() {
      return `/analiseoperacional/relatorio/analise-operacional/csv?` + this.httpParamSerializer(this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar));
    }

    statusLabel(status) {
      switch (status) {
        case 'GPS_INVALIDO':
          return 'label-important ign-desligada';
        case 'PARADO':
          return 'label-parado-anal-op';
        case 'PARADO_COM_MOTOR':
          return 'label-warning';
        case 'MOVIMENTO':
          return 'label-primary';
        default:
          return 'Indefinido';
      }
    }

    statusText(status) {
      switch (status) {
        case 'GPS_INVALIDO':
          return this.translate.instant('ce.enum.movimentoVeiculo.gpsInvalido');
        case 'PARADO':
          return this.translate.instant('ce.enum.movimentoVeiculo.parado');
        case 'PARADO_COM_MOTOR':
          return this.translate.instant('ce.enum.movimentoVeiculo.paradoMotorLigado');
        case 'MOVIMENTO':
          return this.translate.instant('ce.enum.movimentoVeiculo.movimento');
        default:
          return this.translate.instant('ce.enum.movimentoVeiculo.indefinido');
      }
    }

    getConsumoCellFilter() {
      return this.isConsumoEletrico ? 'decimalWithLabel:\'kWh\'' : 'decimalWithLabel:\'L\'';
    }
  }

  angular
    .module('relatorios.analiseOp')
    .controller('AnaliseOpCtrl', AnaliseOpCtrl);
}());
