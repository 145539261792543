(function () {
  'use strict';

  class CodigoIntegracaoCtrl {
    constructor($uibModalInstance, HttpServiceV3g, AlertMessage, pontos, $translate) {
      this.modalInstance = $uibModalInstance;
      this.alertMessage = AlertMessage;
      this.httpService = HttpServiceV3g;
      this.pontos = pontos;
      this.translate = $translate;

      this.codigo = null;
    }

    updatePoints(remover = false) {
      let patchObject = {
        ids: this.pontos.map(i => i.id),
        patch: {
          codigoIntegracao: this.codigo ? this.codigo : null
        }
      };

      this.httpService.post('/ponto-referencia/v2/pontos-referencia/batch/update', patchObject)
        .then((pontosAtualizados) => {
          this.modalInstance.close(pontosAtualizados, remover);
        }).catch(() => {
          this.modalInstance.dismiss({msg: this.translate.instant('ce.cadastro.pontosReferencia.codigoIntegracaoModal.errorMessage')});
        });
    }

    cancel() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('cadastros.pontoReferencia')
    .controller('CodigoIntegracaoCtrl', CodigoIntegracaoCtrl);
}());
