(function () {
  'use strict';

  /* @ngdoc object
   * @name relatorios.historicoVeiculo
   * @description
   *
   */
  angular
    .module('mapas', [
      'leaflet-directive',
      'ui.bootstrap.modal',
      'ModuleMapasUtil',
      'ModuleEventos',
      'pascalprecht.translate',
      'ModuleConvertersUtil',
      'ProntuarioLinkModule'
    ]);
}());
